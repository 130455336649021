import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "baseFont"
};
const _hoisted_2 = {
  class: "number"
};
const _hoisted_3 = {
  key: 0,
  class: "cmpNumberRed"
};
const _hoisted_4 = {
  key: 1,
  class: "cmpNumberGreen"
};
const _hoisted_5 = {
  key: 2,
  src: "https://cdn.waityou.online/519931ce-dcb7-385c-806a-510b55388d21.png",
  style: {
    "width": "12px",
    "height": "12px"
  }
};
const _hoisted_6 = {
  key: 3,
  src: "https://cdn.waityou.online/689b4b89-f0c0-e14d-112b-e945a56f61d5.png",
  style: {
    "width": "12px",
    "height": "12px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_col = _resolveComponent("el-col");
  const _component_MiniLineChart = _resolveComponent("MiniLineChart");
  const _component_MiniBarChart = _resolveComponent("MiniBarChart");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass('preview ' + $props.bgColor)
  }, [_createVNode(_component_el_row, {
    span: 24
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_1, _toDisplayString($props.data.name), 1)]),
    _: 1
  }), _createVNode(_component_el_row, {
    class: "previewData"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 12,
      class: "numberField"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_row, null, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_2, _toDisplayString($props.data.value), 1)]),
        _: 1
      }), _createVNode(_component_el_row, {
        class: "mt8 leftCenter"
      }, {
        default: _withCtx(() => [_cache[0] || (_cache[0] = _createElementVNode("span", {
          class: "cmpSpan"
        }, "较上周", -1)), $props.data.raise ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($props.data.compareValue), 1)) : _createCommentVNode("", true), !$props.data.raise ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($props.data.compareValue), 1)) : _createCommentVNode("", true), $props.data.raise ? (_openBlock(), _createElementBlock("img", _hoisted_5)) : (_openBlock(), _createElementBlock("img", _hoisted_6))]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 12
    }, {
      default: _withCtx(() => [$props.lineChart ? (_openBlock(), _createBlock(_component_MiniLineChart, {
        key: 0,
        data: $props.data.chart
      }, null, 8, ["data"])) : (_openBlock(), _createBlock(_component_MiniBarChart, {
        key: 1,
        data: $props.data.chart
      }, null, 8, ["data"]))]),
      _: 1
    })]),
    _: 1
  })], 2);
}