import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "TeamInfoContainer"
};
const _hoisted_2 = {
  class: "pd12"
};
const _hoisted_3 = {
  class: "baseFont"
};
const _hoisted_4 = {
  class: "pd12"
};
const _hoisted_5 = {
  class: "baseFont lightColor"
};
const _hoisted_6 = {
  class: "pd12"
};
const _hoisted_7 = {
  class: "baseFont lightColor"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$data, _$props$data2, _$props$data3;
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[0] || (_cache[0] = _createElementVNode("span", {
    class: "bigFont boldFont"
  }, "团队信息", -1)), _createElementVNode("span", _hoisted_3, "  " + _toDisplayString(((_$props$data = $props.data) === null || _$props$data === void 0 ? void 0 : _$props$data.type) == 'GROUPING' ? '拼团' : '普通团队'), 1)]), _cache[3] || (_cache[3] = _createElementVNode("div", {
    class: "divider"
  }, null, -1)), _createElementVNode("div", _hoisted_4, [_cache[1] || (_cache[1] = _createElementVNode("span", {
    class: "baseFont boldFont"
  }, "团队名称", -1)), _createElementVNode("span", _hoisted_5, "  " + _toDisplayString((_$props$data2 = $props.data) === null || _$props$data2 === void 0 ? void 0 : _$props$data2.teamName), 1)]), _cache[4] || (_cache[4] = _createElementVNode("div", {
    class: "divider"
  }, null, -1)), _createElementVNode("div", _hoisted_6, [_cache[2] || (_cache[2] = _createElementVNode("span", {
    class: "baseFont boldFont"
  }, "团队人数", -1)), _createElementVNode("span", _hoisted_7, "  " + _toDisplayString((_$props$data3 = $props.data) === null || _$props$data3 === void 0 ? void 0 : _$props$data3.personNum), 1)])]);
}