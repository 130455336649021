import LeaderInfo from "@/components/visit/LeaderInfo";
import TeamInfo from "@/components/visit/TeamInfo";
import VisitComment from "@/components/visit/VisitComment";
import api from "@/api";
import BreadCrumb from "@/components/BreadCrumb";
import DialogView from "@/components/DialogView";
export default {
  name: "VisitDetailView",
  components: {
    DialogView,
    BreadCrumb,
    VisitComment,
    TeamInfo,
    LeaderInfo
  },
  data() {
    return {
      addSpotVisible: false,
      crumb: [{
        name: '参观管理',
        link: '/visit/list'
      }, {
        name: '参观详情'
      }],
      stateMap: {
        INIT: "拼团中",
        COMMIT: "待审核",
        CANCEL: "已取消",
        PASS: "待参观",
        PROCESSING: "参观中",
        FINISH: "参观完成"
      },
      dateId: this.$route.params.dateId,
      uploadPhoneUrl: null,
      visit: {},
      comments: [],
      spots: []
    };
  },
  methods: {
    deleteConfirm(spotId) {
      api.deleteSpot(spotId).then(() => {
        this.init();
      });
    },
    previewVideo(p) {
      window.open(p, '_blank');
    },
    enableComment(cmm) {
      api.updateEvalutionVisible(cmm.id, true).then(() => {
        this.init();
      });
    },
    disEnableComment(cmm) {
      api.updateEvalutionVisible(cmm.id, false).then(() => {
        this.init();
      });
    },
    startVisit() {
      api.setVisitState({
        visitId: this.dateId,
        state: 'PROCESSING'
      }).then(() => {
        this.init();
      });
    },
    finishVisit() {
      api.setVisitState({
        visitId: this.dateId,
        state: 'FINISH'
      }).then(() => {
        this.init();
      });
    },
    addSpot(data) {
      data.visitId = this.dateId;
      api.addSpotImage(data).then(() => {
        this.addSpotVisible = false;
        this.init();
      });
    },
    init() {
      api.getVisitDate(this.dateId).then(ret => {
        this.uploadPhoneUrl = ret.uploadPhoneUrl;
        this.visit = ret.visit;
        this.comments = ret.comments;
        this.spots = ret.spots;
      });
    }
  },
  mounted() {
    this.init();
  }
};