import BreadCrumb from "@/components/BreadCrumb";
import BatterTableDataView from "@/components/BatterTableDataView";
import api from "@/api";
export default {
  name: "SysUserListView",
  components: {
    BatterTableDataView,
    BreadCrumb
  },
  data() {
    return {
      enableMap: {},
      crumb: [{
        name: '系统用户'
      }]
    };
  },
  methods: {
    enableChange(id, val) {
      api.updateUserEnableState(id, val).then(() => {
        this.enableMap[id] = val;
      });
    },
    onFetchData(d) {
      for (let itm of d.list) {
        this.enableMap[itm.id] = itm.enable;
      }
    }
  }
};