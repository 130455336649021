import api from "@/api";
export default {
  name: "KpiAddDialog",
  data() {
    return {
      channels: [],
      propagandistId: null,
      propagandistIds: [],
      orgLevelIds: [],
      platformIds: [],
      govLevelIds: [],
      topicIds: [],
      post: {
        title: '',
        link: '',
        pubInst: '',
        pubUserName: '',
        channelId: null,
        propagandistId: null,
        platformId: null,
        orgLevelId: null,
        govLevelId: null,
        topicId: null,
        viewCnt: 0
      }
    };
  },
  methods: {
    confirm() {
      this.$emit('post', this.post);
    },
    reset() {
      this.post = {};
    },
    handlePropagandistIdChange(value) {
      this.post.propagandistId = value[1];
    },
    init() {
      api.getUserInfo().then(ret => {
        this.post.pubInst = ret.userName;
      });
      api.getEnumValues(0).then(ret => {
        this.channels = ret;
      });
      api.getEnumValues(1).then(ret => {
        this.orgLevelIds = ret;
      });
      api.getEnumValues(3).then(ret => {
        this.platformIds = ret;
      });
      api.getEnumValues(4).then(ret => {
        this.govLevelIds = ret;
      });
      api.getEnumValues(2).then(ret => {
        this.topicIds = ret;
      });
      api.getPropagandist4Cascade().then(ret => {
        this.propagandistIds = ret;
      });
    }
  },
  mounted() {
    this.init();
  }
};