import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = ["onClick", "src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_button = _resolveComponent("el-button");
  const _component_DialogView = _resolveComponent("DialogView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 20
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("div", {
        class: "title"
      }, [_createElementVNode("span", null, "帮助信息设置")], -1)])),
      _: 1
    })]),
    _: 1
  }), _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.helps, (help, i) => {
    return _openBlock(), _createElementBlock("div", {
      style: {
        "padding": "20px"
      },
      key: i
    }, [_createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 4,
        style: {
          "text-align": "right",
          "padding-right": "20px"
        }
      }, {
        default: _withCtx(() => [_createElementVNode("h3", null, _toDisplayString(help.description), 1)]),
        _: 2
      }, 1024), _createVNode(_component_el_col, {
        span: 8
      }, {
        default: _withCtx(() => [help.type === 0 ? (_openBlock(), _createBlock(_component_el_input, {
          key: 0,
          modelValue: help.value,
          "onUpdate:modelValue": $event => help.value = $event,
          autosize: {
            minRows: 4,
            maxRows: 10
          },
          type: "textarea",
          placeholder: "请输入帮助信息"
        }, null, 8, ["modelValue", "onUpdate:modelValue"])) : _createCommentVNode("", true), help.type === 1 ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          style: {
            "width": "80px"
          },
          onClick: $event => {
            $data.imgEditDialogBindKey = help.key;
            $data.imgEditDialogVisible = true;
          },
          src: help.value ? help.value : 'https://cdn.qingbeiyuan.cn/59eb855b-9099-0eae-47d4-6d7a965f8b66.png'
        }, null, 8, _hoisted_2)) : _createCommentVNode("", true)]),
        _: 2
      }, 1024), help.type === 0 ? (_openBlock(), _createBlock(_component_el_col, {
        key: 0,
        span: 4,
        style: {
          "padding": "20px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          type: "primary",
          onClick: $event => $options.updateConfig(i)
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("更新")])),
          _: 2
        }, 1032, ["onClick"])]),
        _: 2
      }, 1024)) : _createCommentVNode("", true)]),
      _: 2
    }, 1024)]);
  }), 128))]), _createVNode(_component_DialogView, {
    "visible-control": $data.imgEditDialogVisible,
    "data-id": 24,
    "bind-id": $data.imgEditDialogBindKey,
    onSubmit: $options.updateImgConfig,
    onOnDialogClose: _cache[0] || (_cache[0] = $event => {
      $data.imgEditDialogVisible = false;
    })
  }, null, 8, ["visible-control", "bind-id", "onSubmit"])], 64);
}