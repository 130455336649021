import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/theme-chalk/el-message.css';
import './icons'
import SvgIcon from "@/components/SvgIcon";
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import ElementPlus from 'element-plus'

router.beforeEach((to, from, next) => {
    if (to.meta.auth) { // 判断该路由是否需要登录权限
        if (localStorage.getItem('access_token')) { //判断本地是否存在access_token
            next();
        } else {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            })
        }
    } else {
        next();
    }
    /*如果本地 存在 token 则 不允许直接跳转到 登录页面*/
    if (to.fullPath === "/login") {
        if (localStorage.getItem('access_token')) {
            next({
                path: from.fullPath
            });
        } else {
            next();
        }
    }
});
const app = createApp(App);
//加载ele-ui icon 开始
for (const key in ElementPlusIconsVue) {
    const componentCfg = ElementPlusIconsVue[key];
    app.component(componentCfg.name, componentCfg);
}
app.component('svg-icon', SvgIcon);
//加载ele-ui icon 结束
app.use(store).use(router).use(ElementPlus, {locale: zhCn,}).mount('#app')
