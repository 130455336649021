import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 20
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("div", {
        class: "title"
      }, [_createElementVNode("span", null, "帖子列表")], -1)])),
      _: 1
    })]),
    _: 1
  }), _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1)), _createVNode(_component_BatterTableDataView, {
    "table-id": 1,
    initQuery: _ctx.initQuery,
    onOnResetQuery: _cache[0] || (_cache[0] = $event => _ctx.initQuery = {})
  }, {
    auditState: _withCtx(data => [_createTextVNode(_toDisplayString(data.mapping[data.rowData]), 1)]),
    postCategory: _withCtx(data => [_createTextVNode(_toDisplayString(data.mapping[data.rowData]), 1)]),
    _: 1
  }, 8, ["initQuery"])]);
}