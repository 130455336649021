import * as echarts from 'echarts';
import shortId from 'shortid';
export default {
  name: "MiniLineChart",
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      chart: null,
      chartId: shortId.generate()
    };
  },
  methods: {
    init() {
      let chartDom = document.getElementById(this.chartId);
      let exist = echarts.getInstanceByDom(chartDom);
      if (exist) {
        console.log(this.id, '销毁已存在的');
        exist.dispose();
      }
      this.chart = echarts.init(chartDom);
      let option = {
        xAxis: {
          type: 'category',
          show: false
        },
        yAxis: {
          type: 'value',
          show: false
        },
        series: [{
          data: this.data,
          type: 'line',
          smooth: true,
          showSymbol: false
        }],
        grid: {
          top: "0",
          right: "0",
          left: "0",
          bottom: "0"
        },
        lineStyle: {
          color: 'blue'
        }
      };
      this.chart.setOption(option);
    }
  },
  mounted() {
    let thiz = this;
    window.onresize = function () {
      if (thiz.chart) {
        thiz.init();
      }
    };
    this.init();
  }
};