const base = {
    baseUrl: "",
    menus: "/api/manager/menu/menuList",
    userInfo: "/api/manager/info/my",
    loginCaptchaToken: "/login/captchaToken",
    login: "/login/accountLogin",
    logout: "/api/user/logout?channel=0",

    getBannerList: "/api/manager/banner/list",
    createBanner: "/api/manager/banner/create",
    updateBanner: "/api/manager/banner/update",
    deleteBanner: "/api/manager/banner/delete?bannerId={0}",

    getOssKeySecret: "/api/manager/oss/getOssKeySecret",
    updateSystemConfig: '/api/manager/config/update',
    getSystemConfig: '/api/manager/config/list',
    updateImageValue: '/api/manager/config/updateImageValue',
    getNextAuditPost: '/api/manager/audit/getNextAuditPost',
    auditPost: '/api/manager/audit/auditPost',
    auditComment: '/api/manager/audit/auditComment',
    auditUser: '/api/manager/audit/auditUser',
    getNextAuditComment: '/api/manager/audit/getNextAuditComment',
    getNextAuditUser: '/api/manager/audit/getNextAuditUser',
    updateQuestionRepo: '/api/manager/study/repo/update',
    updateQuestionRepoEnableState: '/api/manager/study/repo/updateEnable?repoId={0}&enable={1}',
    deleteStudyRepo: '/api/manager/study/repo/delete?repoId={0}',
    createTrainTopic: '/api/manager/train/topic/save',
    createDrillTopic: '/api/manager/drill/topic/save',
    createStudyTopic: '/api/manager/study/data/topic/save',
    updateDrillTopicEnableState: '/api/manager/drill/topic/enableSwitch?id={0}',
    updateTrainTopicEnableState: '/api/manager/train/topic/enableSwitch?id={0}',
    updateStudyTopicEnableState: '/api/manager/study/data/topic/enableSwitch?id={0}',
    getDepartmentList: '/api/manager/department/list4select',
    createStation: '/api/manager/station/save',
    createVisitTpl: '/api/manager/station/visit/template/create',
    updateVisitTpl: '/api/manager/station/visit/template/update',
    updateStationVisitTpl: '/api/manager/station/visit/template/v2/update',
    updateMobileUserEnable: '/api/manager/user/mobile/updateMobileUserEnable',
    getVisitDate: '/api/manager/visit/getById?id={0}',
    addSpotImage: '/api/manager/visit/addSpotImage',
    createQuestionRepoTopic: '/api/manager/study/repo/topic/save',
    updateQuestionRepoTopicEnableState: '/api/manager/study/repo/topic/enableSwitch?id={0}',
    getQuestionDetail: '/api/manager/question/detail?id={0}',
    importQuestionFromFile: '/api/manager/question/importQuestionFromFile?url={0}',
    batchCreateQuestion: '/api/manager/question/batchCreate',
    bindRepoId: '/api/manager/question/bindRepoId',
    checkAndParseQuestion: '/api/manager/question/checkAndParseQuestion',
    createQuestion: '/api/manager/question/create',
    updateUserEnableState: '/api/manager/manager/updateEnableState?id={0}&value={1}',
    updateExamSetting: '/api/manager/study/repo/updateExamSetting',
    getRepoSetting: '/api/manager/study/repo/setting?repoId={0}',
    updateRepoSetting: '/api/manager/study/repo/updateExamSetForm',
    submitVisitAudit: '/api/manager/visit/audit',
    updateEvalutionVisible: '/api/manager/visit/valuation/setVisable?id={0}&visible={1}',
    createQuestionnaire: '/api/manager/questionnaire/create',
    updateQuestion: '/api/manager/question/update',
    updateQuestionnaireSetting: '/api/manager/questionnaire/updateSetting',
    getMapKey: '/api/manager/setting/getMapKey',
    updateStation: '/api/manager/station/save',
    setVisitState: '/api/manager/visit/setVisitState',
    replyLeaveMessage: '/api/manager/leaveMessage/replay',
    submitTrainAudit: '/api/manager/train/audit',
    getTrainDate: '/api/manager/train/detail?id={0}',
    setTrainState: '/api/manager/train/setTrainState',
    submitDrillAudit: '/api/manager/drill/audit',
    getDrillDate: '/api/manager/drill/detail?id={0}',
    setDrillState: '/api/manager/drill/setTrainState',
    updateStationEnable: '/api/manager/station/updateStationEnable',
    getDashboardBaseData: '/api/manager/dashboard/baseData',
    getDashboardStationVisitData: '/api/manager/dashboard/getStationVisitData',
    getSquadronBySuperId: '/api/manager/dashboard/getSquadronBySuperId?superStationId={0}',
    createEnumValue: '/api/manager/enum/create',
    updateEnumValueEnableState: '/api/manager/enum/updateEnumValueEnableState?id={0}&value={1}',
    auditPass: '/api/manager/kpi/assessment/pass',
    auditReject: '/api/manager/kpi/assessment/reject',
    updateTeachListVisible: '/api/manager/train/teacher/updateTeachListVisible?state={0}',
    getTeacherListVisible: '/api/manager/train/teacher/getTeacherListVisible',
    deleteSpot: '/api/manager/visit/deleteSpot?id={0}',
    getPolygon: '/api/manager/station/getPolygon?stationId={0}',
    updatePolygon: '/api/manager/station/updatePolygon',
    updateKpiScore: '/api/manager/kpi/assessment/updateScore?id={0}&score={1}',
    getEnumValues: '/api/manager/enum/list4select/{0}',
    getPropagandist4Select: '/api/manager/kpi/propagandist/list4select',
    getPropagandist4Cascade: '/api/manager/kpi/propagandist/list4cascade',
    addNewPost: '/api/manager/kpi/assessment/create',
    getSysConf: '/api/manager/setting/getSettingByKey',
    updateSysConf: '/api/manager/setting/updateSetting',
    createStationVisitTpl: '/api/manager/station/visit/template/v2/create',
    getStationVisitTemplate: '/api/manager/station/visit/template/v2/list',
    deleteStationVisitTpl: '/api/manager/station/visit/template/v2/delete?id={0}',
    deleteStationVisitTpls: '/api/manager/station/visit/template/v2/deletes',
    fastLogin: '/login/fast/getToken',

    queryForm: {
        1: '/api/manager/post/getQueryForm',
        2: '/api/manager/visit/getQueryForm',
        3: '/api/manager/visit/valuation/getQueryForm',
        4: '/api/manager/banner/getQueryForm',
        5: '/api/manager/drill/getQueryForm',
        6: '/api/manager/drill/valuation/getQueryForm',
        7: '/api/manager/user/getQueryForm',
        8: '/api/manager/study/getQueryForm',
        9: '/api/manager/study/repo/getQueryForm',
        10: '/api/manager/question/getQueryForm',
        11: '/api/manager/train/getQueryForm',
        12: '/api/manager/train/valuation/getQueryForm',
        13: '/api/manager/questionnaire/getQueryForm',
        14: '/api/manager/leaveMessage/getQueryForm',
        15: '/api/manager/user/mobile/getQueryForm',
        16: '/api/manager/manager/getQueryForm',
        17: '/api/manager/role/getQueryForm',
        18: '/api/manager/phoneMessage/getQueryForm',
        19: '/api/manager/kpi/assessment/getQueryForm',
        20: '/api/manager/kpi/propagandist/getQueryForm',
        21: '/api/manager/train/teacher/getQueryForm',
        22: '/api/manager/visit/statistic/getQueryForm',
        23: '/api/manager/kpi/assessment/submit/time/getQueryForm',
    },
    dialogs: {
        1: '/api/manager/station/getEditForm?id={0}',
        2: '/api/manager/study/getCreateForm',
        3: '/api/manager/study/getEditForm?id={0}',
        4: '/api/manager/study/repo/getCreateForm',
        5: '/api/manager/study/repo/getEditForm?repoId={0}',
        6: '/api/manager/question/getCreateForm',
        7: '/api/manager/question/getEditForm?questionId={0}',
        8: '/api/manager/manager/getCreateForm',
        9: '/api/manager/manager/getEditForm?managerId={0}',
        10: '/api/manager/train/topic/getCreateForm',
        11: '/api/manager/train/topic/getEditForm?id={0}',
        12: '/api/manager/drill/topic/getCreateForm',
        13: '/api/manager/drill/topic/getEditForm?id={0}',
        14: '/api/manager/study/data/topic/getCreateForm',
        15: '/api/manager/study/data/topic/getEditForm?id={0}',
        16: '/api/manager/phoneMessage/getDetailForm?id={0}',
        17: '/api/manager/station/detail?id={0}',
        18: '/api/manager/station/getEditTemplateForm?id={0}',
        19: '/api/manager/station/getCreateForm',
        20: '/api/manager/study/repo/topic/getCreateForm',
        21: '/api/manager/study/repo/topic/getEditForm?id={0}',
        22: '/api/manager/role/getCreateForm',
        23: '/api/manager/role/getEditForm?roleId={0}',
        24: '/api/manager/study/repo/setting?repoId={0}',
        25: '/api/manager/visit/getAuditForm?id={0}',
        26: '/api/manager/visit/valuation/detail?id={0}',
        27: '/api/manager/questionnaire/getSettingDialog',
        28: '/api/manager/leaveMessage/detail?id={0}',
        29: '/api/manager/leaveMessage/getReplyForm?id={0}',
        30: '/api/manager/train/getAuditForm?id={0}',
        31: '/api/manager/train/valuation/detail?id={0}',
        32: '/api/manager/drill/getAuditForm?id={0}',
        33: '/api/manager/drill/valuation/detail?id={0}',
        34: '/api/manager/enum/getUpdateForm?id={0}',
        35: '/api/manager/enum/getCreateForm?type={0}',
        36: '/api/manager/kpi/assessment/getEditForm?id={0}',
        37: '/api/manager/kpi/assessment/getCreateForm',
        38: '/api/manager/kpi/propagandist/getEditForm?id={0}',
        39: '/api/manager/kpi/propagandist/getCreateForm',
        40: '/api/manager/kpi/assessment/detail?id={0}',
        41: '/api/manager/kpi/assessment/getPassForm',
        42: '/api/manager/kpi/assessment/getRejectForm',
        43: '/api/manager/train/teacher/getCreateForm',
        44: '/api/manager/train/teacher/getEditForm?id={0}',
        45: '/api/manager/visit/getAddSpotForm',
        46: '/api/manager/kpi/assessment/submit/time/getCreateForm',
        47: '/api/manager/station/visit/template/v2/getCreateForm?date={0}',
        48: '/api/manager/station/visit/template/v2/getEditForm?id={0}',
    },
    table: {
        1: {
            list: '/api/manager/station/list',
            create: '/api/manager/station/save',
            delete: '/api/manager/station/delete',
            deleteKey: 'id',
            // updateDialog: 1,
            // update: '/api/manager/station/save',
            // updateKey: 'id',
            detailDialog: 17,
            detailKey: 'id'
        },
        2: {
            list: '/api/manager/station/visit/template/list',
            delete: '/api/manager/station/visit/template/delete',
            deleteKey: 'id',
        },
        3: {
            listMethod:'post',
            list: '/api/manager/visit/list',
            export: '/api/manager/visit/export',
            queryForm: 2
        },
        4: {
            list: '/api/manager/visit/valuation/list',
            export: '/api/manager/visit/valuation/export',
            queryForm: 3,
            detailDialog: 26,
            detailKey: 'id',
        },
        5: {
            listMethod:'post',
            list: '/api/manager/drill/list',
            export: '/api/manager/drill/export',
            queryForm: 5
        },
        6: {
            list: '/api/manager/drill/valuation/list',
            export: '/api/manager/drill/valuation/export',
            queryForm: 6,
            detailDialog: 33,
            detailKey: 'id'
        },
        7: {
            list: '/api/manager/study/list',
            queryForm: 8,
            create: '/api/manager/study/create',
            createDialog: 2,
            updateKey: 'dataId',
            updateDialog: 3,
            update: '/api/manager/study/update',
            delete: '/api/manager/study/delete',
            deleteKey: 'id'
        },
        8: {
            list: '/api/manager/study/repo/list',
            queryForm: 9,
            create: '/api/manager/study/repo/create',
            createDialog: 4,
            editKey: 'repoId',
            updateDialog: 5,
        },
        9: {
            list: '/api/manager/question/list',
            queryForm: 10,
            create: '/api/manager/question/create',
            deleteKey: 'id',
            delete: '/api/manager/question/delete',
            // updateDialog: 7,
            // update: '/api/manager/question/update',

        },
        10: {
            listMethod:'post',
            list: '/api/manager/train/list',
            export: '/api/manager/train/export',
            queryForm: 11,
            create: '/api/manager/train/create',
            createDialog: 6,
            updateDialog: 7,
            update: '/api/manager/train/update',
        },
        11: {
            list: '/api/manager/train/valuation/list',
            export: '/api/manager/train/valuation/export',
            queryForm: 12,
            create: '/api/manager/train/valuation/create',
            createDialog: 6,
            updateDialog: 7,
            update: '/api/manager/train/valuation/update',
            detailDialog: 31,
            detailKey: 'id'
        },
        12: {
            list: '/api/manager/questionnaire/list',
            queryForm: 13,

        },
        13: {
            list: '/api/manager/leaveMessage/list',
            queryForm: 14,
            detailDialog: 28,
            detailKey: 'id',
            delete: '/api/manager/leaveMessage/delete',
            deleteKey: 'id',
        },
        14: {
            list: '/api/manager/user/mobile/list',
            queryForm: 15,
        },
        15: {
            list: '/api/manager/manager/list',
            queryForm: 16,
            createDialog: 8,
            create: '/api/manager/manager/save',
            updateDialog: 9,
            update: '/api/manager/manager/save',
            updateKey: 'id',
            delete: '/api/manager/manager/delete',
            deleteKey: 'id',
        },
        16: {
            list: '/api/manager/role/list',
            queryForm: 17,
            createDialog: 22,
            create: '/api/manager/role/create',
            updateDialog: 23,
            update: '/api/manager/role/update',
            updateKey: 'roleId',
            delete: '/api/manager/role/delete',
            deleteKey: 'roleId',
        },
        17: {
            list: '/api/manager/train/topic/list',
            // queryForm: 18,
            createDialog: 10,
            create: '/api/manager/train/topic/save',
            updateDialog: 11,
            update: '/api/manager/train/topic/save',
            updateKey: 'id',
            delete: '/api/manager/train/topic/delete',
            deleteKey: 'id'
        },
        18: {
            list: '/api/manager/drill/topic/list',
            // queryForm: 18,
            createDialog: 12,
            create: '/api/manager/drill/topic/save',
            updateDialog: 13,
            update: '/api/manager/drill/topic/save',
            updateKey: 'id',
            delete: '/api/manager/drill/topic/delete',
            deleteKey: 'id'
        },
        19: {
            list: '/api/manager/study/data/topic/list',
            // queryForm: 18,
            createDialog: 14,
            create: '/api/manager/study/data/topic/save',
            updateDialog: 15,
            update: '/api/manager/study/data/topic/save',
            updateKey: 'id',
            delete: '/api/manager/study/data/topic/delete',
            deleteKey: 'id'
        },
        20: {
            list: '/api/manager/loginRecord/list',
        },
        21: {
            list: '/api/manager/phoneMessage/list',
            queryForm: 18,
            detailDialog: 16,
        },
        22: {
            list: '/api/manager/study/repo/topic/list',
            // queryForm: 18,
            createDialog: 20,
            create: '/api/manager/study/repo/topic/save',
            updateDialog: 21,
            update: '/api/manager/study/repo/topic/save',
            updateKey: 'id',
            delete: '/api/manager/study/repo/topic/delete',
            deleteKey: 'id'
        },
        23: {
            list: '/api/manager/enum/list',
            updateDialog: 34,
            update: '/api/manager/enum/update',
            updateKey: 'id',
            delete: '/api/manager/enum/delete',
            deleteKey: 'id',
        },
        24: {
            listMethod: 'post',
            queryForm: 19,
            list: '/api/manager/kpi/assessment/list',
            export: '/api/manager/kpi/assessment/export',
            // createDialog: 37,
            updateDialog: 36,
            detailDialog: 40,
            // create: '/api/manager/kpi/assessment/create',
            update: '/api/manager/kpi/assessment/update',
            detailKey: 'id',
            updateKey: 'id',
            delete: '/api/manager/kpi/assessment/delete',
            deleteKey: 'id',
        },
        25: {
            queryForm: 20,
            list: '/api/manager/kpi/propagandist/list',
            createDialog: 39,
            updateDialog: 38,
            create: '/api/manager/kpi/propagandist/save',
            update: '/api/manager/kpi/propagandist/save',
            updateKey: 'id',
            delete: '/api/manager/kpi/propagandist/delete',
            deleteKey: 'id',
        },
        26: {
            queryForm: 21,
            list: '/api/manager/train/teacher/list',
            createDialog: 43,
            updateDialog: 44,
            create: '/api/manager/train/teacher/create',
            update: '/api/manager/train/teacher/update',
            updateKey: 'id',
            delete: '/api/manager/train/teacher/delete',
            deleteKey: 'id',
        },
        27: {
            queryForm: 22,
            list: '/api/manager/visit/statistic/list',
            export: '/api/manager/visit/statistic/export',
        },
        28: {
            queryForm: 23,
            list: '/api/manager/kpi/assessment/submit/time/list',
            createDialog: 46,
            create: '/api/manager/kpi/assessment/submit/time/create',
            delete: '/api/manager/kpi/assessment/submit/time/delete',
            deleteKey:'id',
        },
    }

}

export default base;