import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  style: {
    "border": "#CDDBF7 1px solid"
  },
  class: "brd8 wd100 StationNumberViewContainer"
};
const _hoisted_2 = {
  class: "lightColor"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_progress = _resolveComponent("el-progress");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_select, {
    style: {
      "width": "50%"
    },
    modelValue: $data.numberType,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.numberType = $event),
    onChange: $options.init
  }, {
    default: _withCtx(() => [_createVNode(_component_el_option, {
      value: "0",
      label: "参观次数"
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("参观次数")])),
      _: 1
    }), _createVNode(_component_el_option, {
      value: "1",
      label: "参观人次"
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("参观人次")])),
      _: 1
    }), _createVNode(_component_el_option, {
      value: "2",
      label: "浏览数"
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("浏览数")])),
      _: 1
    }), _createVNode(_component_el_option, {
      value: "3",
      label: "点赞数"
    }, {
      default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("点赞数")])),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue", "onChange"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.values, (v, vi) => {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: vi
    }, [_createVNode(_component_el_row, {
      class: "bothCenter miniFont mt16"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 12,
        class: "leftCenter"
      }, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_2, _toDisplayString(v.name), 1)]),
        _: 2
      }, 1024), _createVNode(_component_el_col, {
        span: 12,
        class: "rightCenter"
      }, {
        default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(v.value), 1)]),
        _: 2
      }, 1024)]),
      _: 2
    }, 1024), _createVNode(_component_el_progress, {
      percentage: $data.maxValue > 0 ? parseInt(v.value * 100 / $data.maxValue) : 0,
      "show-text": false,
      class: "mt16",
      color: $data.colors[vi % $data.colors.length]
    }, null, 8, ["percentage", "color"])], 64);
  }), 128))]);
}