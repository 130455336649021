import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_svg_icon = _resolveComponent("svg-icon");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_button = _resolveComponent("el-button");
  const _component_Upload = _resolveComponent("Upload");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_OssUploader = _resolveComponent("OssUploader");
  return _openBlock(), _createBlock(_component_el_row, {
    style: {
      "display": "flex",
      "flex-direction": "column",
      "border": "1px lightgray dashed",
      "padding": "20px",
      "margin": "10px 0"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 24,
      style: {
        "display": "flex",
        "justify-content": "center"
      }
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("span", null, "导入模板试题", -1)])),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 24,
      style: {
        "display": "flex",
        "justify-content": "center"
      }
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("span", {
        class: "redNotice"
      }, "请先下载格式模板，参考模板，调整自己的文档格式，再进行上传", -1)])),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 24,
      style: {
        "display": "flex",
        "justify-content": "center"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 5
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode(" ")])),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 7,
        style: {
          "border": "#CCCCCC 1px solid",
          "padding": "10px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_row, {
          class: "bothCenter"
        }, {
          default: _withCtx(() => [_createVNode(_component_svg_icon, {
            "icon-class": "word",
            size: "50px"
          })]),
          _: 1
        }), _createVNode(_component_el_row, {
          class: "bothCenter"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("span", {
            class: "subTitle"
          }, "Word模板导入", -1)])),
          _: 1
        }), _createVNode(_component_el_row, null, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [_createElementVNode("span", {
            class: "smallText"
          }, [_createTextVNode("1、不能使用文档的自动编号功能，编辑试题序号。"), _createElementVNode("a", {
            href: "#"
          }, "如何取消自动编号")], -1), _createElementVNode("span", {
            class: "smallText"
          }, [_createTextVNode("2、按照模板格式，编辑输入试题文档。"), _createElementVNode("a", {
            href: "#"
          }, "查看教程")], -1)])),
          _: 1
        }), _createVNode(_component_el_row, {
          class: "bothCenter",
          style: {
            "padding": "10px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            size: "large",
            style: {
              "color": "#2165FF",
              "border-color": "#2165FF"
            },
            onClick: _cache[0] || (_cache[0] = $event => $options.downloadFile('https://cdn.waityou.online/bf23c253-defe-bb29-651b-da50f8022b79.docx'))
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode(" Word模板下载 ")])),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 1
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode(" ")])),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 1
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode(" ")])),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 7,
        style: {
          "border": "#CCCCCC 1px solid",
          "padding": "10px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_row, {
          class: "bothCenter"
        }, {
          default: _withCtx(() => [_createVNode(_component_svg_icon, {
            "icon-class": "excel",
            size: "50px"
          })]),
          _: 1
        }), _createVNode(_component_el_row, {
          class: "bothCenter"
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [_createElementVNode("span", {
            class: "subTitle"
          }, "Excel模板导入", -1)])),
          _: 1
        }), _createVNode(_component_el_row, null, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [_createElementVNode("span", {
            class: "smallText"
          }, "1、图片上传，图片放在单元格内", -1), _createElementVNode("span", {
            class: "smallText"
          }, "2、不支持公式 ", -1), _createElementVNode("span", {
            class: "smallText"
          }, "3、按照模板格式，编辑输入试题文档。", -1), _createElementVNode("span", {
            class: "smallText"
          }, [_createTextVNode("4、案例分析/案列题批量导入，请单独下载。Excel案例分析模板 "), _createElementVNode("a", {
            href: "#"
          }, "查看教程")], -1)])),
          _: 1
        }), _createVNode(_component_el_row, {
          class: "bothCenter",
          style: {
            "padding": "10px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            size: "large",
            style: {
              "color": "#58A55C",
              "border-color": "#58A55C"
            },
            onClick: _cache[1] || (_cache[1] = $event => $options.downloadFile('https://cdn.waityou.online/a83c3013-330f-3949-4e32-e566ca814cc0.xlsx'))
          }, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode(" Excel模板下载 ")])),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 5
      }, {
        default: _withCtx(() => _cache[13] || (_cache[13] = [_createTextVNode(" ")])),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 24,
      style: {
        "display": "flex",
        "align-items": "center",
        "padding": "30px",
        "flex-direction": "column"
      }
    }, {
      default: _withCtx(() => [(_openBlock(), _createBlock(_component_OssUploader, {
        key: $data.uploaderVersion,
        onOnUploaded: $options.onFileUploaded,
        "allowed-file-types": ['xlsx', 'doc', 'docx']
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          size: "large",
          style: {
            "background-color": "#005FF5",
            "color": "white",
            "width": "200px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [_createVNode(_component_Upload)]),
            _: 1
          }), _cache[14] || (_cache[14] = _createTextVNode(" 上传题库 "))]),
          _: 1
        })]),
        _: 1
      }, 8, ["onOnUploaded"]))]),
      _: 1
    })]),
    _: 1
  });
}