import BatterTableDataView from "@/components/BatterTableDataView";
import DialogView from "@/components/DialogView";
import api from "@/api";
import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "FeedbackView",
  components: {
    BreadCrumb,
    DialogView,
    BatterTableDataView
  },
  data() {
    return {
      replyId: null,
      replyVisible: false,
      crumb: [{
        name: '留言管理'
      }]
    };
  },
  methods: {
    reply(mid) {
      this.replyId = mid;
      this.replyVisible = true;
    },
    replyMessage(data) {
      data.leaveMessageId = this.replyId;
      api.replyLeaveMessage(data).then(() => {
        this.replyVisible = false;
      });
    }
  }
};