import BatterTableDataView from "@/components/BatterTableDataView";
import api from "@/api";
import DialogCustomView from "@/components/DialogCustomView";
import { ElMessage } from "element-plus";
import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "StudyDataView",
  components: {
    BreadCrumb,
    DialogCustomView,
    BatterTableDataView
  },
  data() {
    return {
      switches: {},
      editDialogVisible: false,
      configVisible: false,
      editBindId: 0,
      repoId: 0,
      setting: {},
      crumb: [{
        name: '题库管理'
      }]
    };
  },
  methods: {
    updateRepoSetting() {
      let setting = this.setting;
      let total = setting.questionDetails[0].count * setting.questionDetails[0].score + setting.questionDetails[1].count * setting.questionDetails[1].score + setting.questionDetails[2].count * setting.questionDetails[2].score;
      if (total < setting.passScore) {
        ElMessage.error('合格分数不能超过总分');
        return;
      }
      api.updateRepoSetting({
        repoId: this.repoId,
        passScore: this.setting.passScore,
        singleScore: this.setting.questionDetails[0].score,
        multiScore: this.setting.questionDetails[1].score,
        judgeScore: this.setting.questionDetails[2].score
      }).then(() => {
        this.configVisible = false;
      });
    },
    examSetting(repoId) {
      this.repoId = repoId;
      api.getRepoSetting(repoId).then(ret => {
        this.setting = ret;
        this.configVisible = true;
      });
    },
    onFetchData(data) {
      let list = data['list'];
      for (let l of list) {
        this.switches[l['id']] = l['enable'];
      }
    },
    updateData(data) {
      data['repoId'] = this.editBindId;
      api.updateQuestionRepo(data).then(() => {
        this.editDialogVisible = false;
        this.$refs.repoTable.fetchData();
      });
    },
    updateEnableState(repoId, enable) {
      api.updateQuestionRepoEnableState(repoId, enable).then(() => {
        this.switches[repoId] = enable;
      });
    },
    deleteRepo(repoId) {
      api.deleteStudyRepo(repoId).then(() => {
        this.$refs.repoTable.fetchData();
      });
    }
  }
};