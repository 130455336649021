import DialogCustomView from "@/components/DialogCustomView";
import BatterTableDataView from "@/components/BatterTableDataView";
import QuestionCreateView from "@/components/study/QuestionCreateView";
import api from "@/api";
import { ElMessage } from "element-plus";
import DialogView from "@/components/DialogView";
import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "QuestionnaireListView",
  components: {
    BreadCrumb,
    DialogView,
    QuestionCreateView,
    DialogCustomView,
    BatterTableDataView
  },
  data() {
    return {
      settingVisible: false,
      createVisible: false,
      editVisible: false,
      questionOptions: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'L', 'N'],
      updateId: null,
      crumb: [{
        name: '问卷管理'
      }]
    };
  },
  methods: {
    onCreateMore() {
      this.createVisible = true;
    },
    createQuestion(data) {
      api.createQuestionnaire(data).then(() => {
        ElMessage.success('添加成功');
        this.createVisible = false;
        this.$refs.QuestionnaireTable.fetchData();
      });
    },
    updateSetting(data) {
      api.updateQuestionnaireSetting(data).then(() => {
        this.settingVisible = false;
      });
    },
    updateQuestion(data) {
      api.updateQuestion(data).then(() => {
        this.editVisible = false;
        this.$refs.QuestionnaireTable.fetchData();
      });
    },
    onEditData(data) {
      this.updateId = data.id;
      this.editVisible = true;
    }
  }
};