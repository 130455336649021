import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_TableDataView = _resolveComponent("TableDataView");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 20
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("div", {
        class: "title"
      }, [_createElementVNode("span", null, "Banner列表")], -1)])),
      _: 1
    })]),
    _: 1
  }), _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1)), _createVNode(_component_TableDataView, {
    listFunction: $data.listFun,
    "form-id": 4,
    "detail-id-key": "bannerId",
    "detail-dialog-id": 12,
    "create-function": $data.createFun,
    "update-function": $data.updateFun,
    "delete-function": $data.deleteFun,
    "create-dialog-id": 7,
    "update-id-key": "bannerId",
    "edit-dialog-id": 8,
    "query-form": true
  }, {
    image: _withCtx(data => [_createElementVNode("img", {
      src: data.rowData,
      style: {
        "height": "50px"
      }
    }, null, 8, _hoisted_2)]),
    type: _withCtx(data => [_createTextVNode(_toDisplayString(data.mapping[data.rowData]), 1)]),
    _: 1
  }, 8, ["listFunction", "create-function", "update-function", "delete-function"])]);
}