import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "background-color": "white"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_row = _resolveComponent("el-row");
  const _component_DataPreview = _resolveComponent("DataPreview");
  const _component_el_col = _resolveComponent("el-col");
  const _component_VerticalBarChart = _resolveComponent("VerticalBarChart");
  const _component_DashboardStations = _resolveComponent("DashboardStations");
  const _component_StationNumberView = _resolveComponent("StationNumberView");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "dataPreview"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("span", {
      class: "bigFont"
    }, "数据概览", -1)])),
    _: 1
  }), $data.baseData.length > 0 ? (_openBlock(), _createBlock(_component_el_row, {
    key: 0
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 6,
      class: "dataPreviewBlock"
    }, {
      default: _withCtx(() => [_createVNode(_component_DataPreview, {
        data: $data.baseData[0]
      }, null, 8, ["data"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 6,
      class: "dataPreviewBlock"
    }, {
      default: _withCtx(() => [_createVNode(_component_DataPreview, {
        data: $data.baseData[1],
        "line-chart": false,
        "bg-color": "green",
        raise: false
      }, null, 8, ["data"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 6,
      class: "dataPreviewBlock"
    }, {
      default: _withCtx(() => [_createVNode(_component_DataPreview, {
        data: $data.baseData[2]
      }, null, 8, ["data"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 6,
      class: "dataPreviewBlock"
    }, {
      default: _withCtx(() => [_createVNode(_component_DataPreview, {
        data: $data.baseData[3],
        "bg-color": "pink"
      }, null, 8, ["data"])]),
      _: 1
    })]),
    _: 1
  })) : _createCommentVNode("", true), _createVNode(_component_el_row, {
    class: "pd16"
  }, {
    default: _withCtx(() => [$data.stations.length > 0 ? (_openBlock(), _createBlock(_component_VerticalBarChart, {
      key: 0,
      style: {
        "border": "#CDDBF7 1px solid"
      },
      onStationClick: $options.onStationClick,
      class: "brd8",
      data: $data.stations
    }, null, 8, ["onStationClick", "data"])) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 16,
      style: {
        "display": "flex",
        "padding": "16px 8px 16px 16px"
      }
    }, {
      default: _withCtx(() => [$data.stationWithSub != null ? (_openBlock(), _createBlock(_component_DashboardStations, {
        key: 0,
        data: $data.stationWithSub
      }, null, 8, ["data"])) : _createCommentVNode("", true)]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 8,
      style: {
        "display": "flex",
        "padding": "16px 16px 16px 8px"
      }
    }, {
      default: _withCtx(() => [$data.stationWithSub != null ? (_openBlock(), _createBlock(_component_StationNumberView, {
        key: 0,
        data: $data.stationWithSub
      }, null, 8, ["data"])) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  })]);
}