import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "common-layout"
};
const _hoisted_2 = {
  class: "header user-name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_svg_icon = _resolveComponent("svg-icon");
  const _component_el_avatar = _resolveComponent("el-avatar");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_popover = _resolveComponent("el-popover");
  const _component_el_header = _resolveComponent("el-header");
  const _component_AsideMenu = _resolveComponent("AsideMenu");
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_main = _resolveComponent("el-main");
  const _component_el_container = _resolveComponent("el-container");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, null, {
    default: _withCtx(() => [_createVNode(_component_el_header, {
      class: "header"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_row, null, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 12,
          class: "header left"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("img", {
            src: "/favicon.png",
            class: "title-logo"
          }, null, -1), _createElementVNode("span", {
            class: "header title"
          }, "杭州消防救援便民服务平台", -1)])),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 12,
          class: "header right"
        }, {
          default: _withCtx(() => [_createVNode(_component_svg_icon, {
            "icon-class": "ring"
          }), _cache[3] || (_cache[3] = _createElementVNode("span", {
            class: "notice mr16"
          }, "通知", -1)), _createElementVNode("span", _hoisted_2, "|   " + _toDisplayString(_ctx.$store.state.user.userName), 1), _createVNode(_component_el_popover, {
            trigger: "click"
          }, {
            reference: _withCtx(() => [_createVNode(_component_el_avatar, {
              size: 33,
              "src-set": _ctx.$store.state.user.avatar
            }, null, 8, ["src-set"])]),
            default: _withCtx(() => [_createVNode(_component_el_row, {
              class: "bothCenter colFlex"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_button, {
                type: "primary",
                style: {
                  "width": "100%"
                },
                onClick: $options.logout
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("退出登录")])),
                _: 1
              }, 8, ["onClick"]), _cache[2] || (_cache[2] = _createElementVNode("span", {
                class: "ft10 mt4",
                style: {
                  "color": "#9db2ec"
                }
              }, "v1.3.20", -1))]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_container, {
      class: "container"
    }, {
      default: _withCtx(() => [_createVNode(_component_AsideMenu), _createVNode(_component_el_main, {
        class: "main-view"
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}