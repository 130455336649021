import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_DialogView = _resolveComponent("DialogView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", null, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_button, {
      class: "primaryBtn",
      icon: 'plus',
      onClick: _cache[0] || (_cache[0] = $event => $data.createVisible = true)
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("添加")])),
      _: 1
    })]),
    _: 1
  }), (_openBlock(), _createBlock(_component_BatterTableDataView, {
    "table-id": "23",
    "init-query": {
      'type': $props.type
    },
    key: $props.type,
    ref: "enumValueTable",
    onOnFetchData: $options.onFetchData
  }, {
    enable: _withCtx(data => [_createVNode(_component_el_switch, {
      "model-value": $data.switches[data.rowId],
      onChange: val => $options.updateEnableState(data.rowId, val),
      class: "ml-2",
      style: {
        "--el-switch-on-color": "#015EF5",
        "--el-switch-off-color": "lightgray"
      }
    }, null, 8, ["model-value", "onChange"])]),
    _: 1
  }, 8, ["init-query", "onOnFetchData"]))]), _createVNode(_component_DialogView, {
    "visible-control": $data.createVisible,
    "data-id": 35,
    "bind-id": $props.type,
    onOnDialogClose: _cache[1] || (_cache[1] = $event => $data.createVisible = false),
    onSubmit: $options.createEnumValue
  }, null, 8, ["visible-control", "bind-id", "onSubmit"])], 64);
}