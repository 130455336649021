import MiniLineChart from "@/components/dashboard/MiniLineChart";
import MiniBarChart from "@/components/dashboard/MiniBarChart";
export default {
  name: "DataPreview",
  components: {
    MiniBarChart,
    MiniLineChart
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    lineChart: {
      type: Boolean,
      default: true
    },
    bgColor: {
      type: String,
      default: 'blue'
    }
  }
};