import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "StationInfoCardContainer"
};
const _hoisted_2 = {
  class: "mr12"
};
const _hoisted_3 = {
  key: 0,
  style: {
    "background": "#005FF5",
    "color": "white",
    "padding": "2px 10px",
    "border-radius": "20px"
  }
};
const _hoisted_4 = {
  class: "lightColor"
};
const _hoisted_5 = {
  style: {
    "color": "rgba(16, 16, 16, 1)"
  }
};
const _hoisted_6 = {
  class: "colFlex"
};
const _hoisted_7 = {
  class: "lightColor"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "wd100 smallFont"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 4
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("img", {
        src: "https://cdn.waityou.online/7146f309-5b57-d423-c3fa-19a4c5cee386.png",
        style: {
          "width": "50px"
        }
      }, null, -1)])),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 20,
      class: "colFlex"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_row, {
        class: "bothCenter"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 16,
          class: "leftCenter"
        }, {
          default: _withCtx(() => [_createElementVNode("span", _hoisted_2, _toDisplayString($props.data.name), 1), $props.data.centralStation == 'YES' ? (_openBlock(), _createElementBlock("span", _hoisted_3, "中心站")) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 8,
          class: "rightCenter"
        }, {
          default: _withCtx(() => [_createElementVNode("span", _hoisted_4, _toDisplayString($props.data.openState == 'YES' ? '开放' : '不开放'), 1)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_row, {
        class: "mt8"
      }, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_5, _toDisplayString($props.data.address), 1)]),
        _: 1
      }), $props.data.stationTemplate && $props.data.stationTemplate.visitTimeList ? (_openBlock(), _createBlock(_component_el_row, {
        key: 0,
        class: "mt8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_row, {
          class: "wd100"
        }, {
          default: _withCtx(() => [_cache[1] || (_cache[1] = _createElementVNode("span", null, "开放时间：", -1)), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data.stationTemplate.visitTimeList, (t, ti) => {
            return _openBlock(), _createElementBlock("span", {
              key: ti,
              class: "lightColor"
            }, _toDisplayString($data.weekType[$props.data.stationTemplate.visitDateType]) + _toDisplayString($data.week[t.week]) + " " + _toDisplayString(t.startTime) + " - " + _toDisplayString(t.endTime), 1);
          }), 128))])]),
          _: 1
        })]),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_el_row, {
        class: "mt8"
      }, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_7, "浏览数：" + _toDisplayString($props.data.browseCount) + " 点赞数 " + _toDisplayString($props.data.likeCount), 1)]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}