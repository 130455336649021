import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "FileImportReviewContainer"
};
const _hoisted_2 = {
  class: "box"
};
const _hoisted_3 = {
  class: "scrollContainer"
};
const _hoisted_4 = {
  class: "box"
};
const _hoisted_5 = {
  style: {
    "color": "red"
  }
};
const _hoisted_6 = {
  class: "scrollContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_radio = _resolveComponent("el-radio");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_QuestionListItem = _resolveComponent("QuestionListItem");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    className: "leftCenter"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 12
    }, {
      default: _withCtx(() => [_cache[9] || (_cache[9] = _createElementVNode("span", null, "难易程度   ", -1)), _createVNode(_component_el_radio_group, {
        modelValue: $data.hardType,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.hardType = $event),
        onChange: $options.onHardTypeChange
      }, {
        default: _withCtx(() => [_createVNode(_component_el_radio, {
          label: "VERY_EASY",
          size: "large"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("易")])),
          _: 1
        }), _createVNode(_component_el_radio, {
          label: "EASY",
          size: "large"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("偏易")])),
          _: 1
        }), _createVNode(_component_el_radio, {
          label: "MID",
          size: "large"
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("适中")])),
          _: 1
        }), _createVNode(_component_el_radio, {
          label: "LITTLE_HARD",
          size: "large"
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("偏难")])),
          _: 1
        }), _createVNode(_component_el_radio, {
          label: "HARD",
          size: "large"
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("难")])),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue", "onChange"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 12,
      class: "rightCenter"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        size: "normal",
        type: "primary",
        onClick: $options.checkInput
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode("检查输入")])),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_el_button, {
        size: "normal",
        onClick: _cache[1] || (_cache[1] = $event => _ctx.$emit('reUpload'))
      }, {
        default: _withCtx(() => _cache[11] || (_cache[11] = [_createTextVNode("重新上传")])),
        _: 1
      }), _createVNode(_component_el_button, {
        size: "normal",
        onClick: _cache[2] || (_cache[2] = $event => _ctx.$emit('save', $data.parsed))
      }, {
        default: _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode("保存")])),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_row, {
    class: "bothCenter",
    gutter: 10
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 12,
      class: "leftCenter"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_cache[13] || (_cache[13] = _createElementVNode("span", {
        class: "title"
      }, "输入区", -1)), _createVNode(_component_el_divider, {
        style: {
          "margin": "10px 0"
        }
      }), _createElementVNode("div", _hoisted_3, [_withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.fileContent = $event),
        style: {
          "height": "100%",
          "border": "none",
          "font-size": "16px"
        }
      }, "            ", 512), [[_vModelText, $data.fileContent]])])])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 12,
      class: "leftCenter"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_row, {
        class: "bothCenter"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 4,
          class: "leftCenter"
        }, {
          default: _withCtx(() => _cache[14] || (_cache[14] = [_createElementVNode("span", {
            class: "title"
          }, "检查区", -1)])),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 20,
          class: "rightCenter",
          style: {
            "font-size": "14px"
          }
        }, {
          default: _withCtx(() => [_cache[15] || (_cache[15] = _createElementVNode("span", null, "检测到", -1)), _createElementVNode("span", _hoisted_5, _toDisplayString($data.wrongCnt), 1), _createElementVNode("span", null, "处错误，共上传" + _toDisplayString($data.howMany) + "题，单选" + _toDisplayString($data.selectCnt) + "道，多选" + _toDisplayString($data.mSelectCnt) + "道，判断" + _toDisplayString($data.judgeCnt) + "道", 1)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_divider, {
        style: {
          "margin": "10px 0"
        }
      }), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.parsed, (q, i) => {
        return _openBlock(), _createBlock(_component_QuestionListItem, {
          onUpdateAnswer: r => $options.updateAnswer(i, r),
          onDelete: $event => $options.remove(i),
          question: q,
          "q-index": i,
          key: q.description
        }, null, 8, ["onUpdateAnswer", "onDelete", "question", "q-index"]);
      }), 128))])])]),
      _: 1
    })]),
    _: 1
  })]);
}