import BatterTableDataView from "@/components/BatterTableDataView";
import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "KpiPropagandistListView",
  components: {
    BatterTableDataView,
    BreadCrumb
  },
  data() {
    return {
      createVisible: false,
      initQuery: {},
      switches: {},
      crumb: [{
        name: '宣传员管理'
      }]
    };
  },
  methods: {}
};