import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "background-color": "white"
  }
};
const _hoisted_2 = {
  class: "pd20"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_el_row = _resolveComponent("el-row");
  const _component_QuestionImportPageView = _resolveComponent("QuestionImportPageView");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_cache[0] || (_cache[0] = _createElementVNode("span", {
      class: "pageTitle padding20"
    }, "题库管理/试题管理", -1)), _createVNode(_component_el_divider, {
      class: "padding0 margin0"
    })]),
    _: 1
  }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_QuestionImportPageView, {
    onSubmit: $options.submitQids
  }, null, 8, ["onSubmit"])])]);
}