export default {
  name: "StationInfoCard",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      weekType: {
        ALL_MONTH: '每周',
        MONTH_SINGLE_WEEK: '每月单周',
        MONTH_DOUBLE_WEEK: '每月双周'
      },
      week: {
        6: '六',
        7: '日'
      }
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};