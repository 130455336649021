import api from "@/api";
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  name: "SetPolygonView",
  props: {
    stationId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      amapKey: "",
      longitude: null,
      latitude: null,
      polygons: [],
      polygon: null,
      zoom: 16,
      map: null,
      polygonEditor: null
    };
  },
  methods: {
    saveEditor() {
      this.polygonEditor.close();
    },
    onPolygonSave() {
      let points = this.polygon.getPath().map(p => {
        return {
          longitude: p.getLng(),
          latitude: p.getLat()
        };
      });
      api.updatePolygon({
        stationId: this.stationId,
        polygons: points
      }).then(() => {
        this.$emit('close');
      });
    },
    init() {
      api.getMapKey().then(ret => {
        this.amapKey = ret;
        this.initPolygon();
      });
    },
    initPolygon() {
      api.getPolygon(this.stationId).then(ret => {
        this.longitude = ret.longitude;
        this.latitude = ret.latitude;
        this.polygons = ret.polygons;
        this.initMap();
      });
    },
    initMap() {
      AMapLoader.load({
        key: this.amapKey,
        version: "2.0",
        plugins: ['']
      }).then(AMap => {
        this.map = new AMap.Map("amapContainer", {
          //设置地图容器id
          viewMode: "2D",
          zoom: this.zoom,
          center: [this.longitude, this.latitude]
        });
        this.map.on('click', this.onMapClick);
        this.map.add(this.generateMark(AMap));
        this.polygon = this.addPolygon(AMap);
        this.map.add(this.polygon);
        this.map.setFitView([this.polygon]);
        this.initPolygonEditor(AMap);
      }).catch(e => {
        console.log(e);
      });
    },
    initPolygonEditor(AMap) {
      // 引入多边形编辑器插件
      let thiz = this;
      this.map.plugin(["AMap.PolygonEditor"], function () {
        // 实例化多边形编辑器，传入地图实例和要进行编辑的多边形实例
        thiz.polygonEditor = new AMap.PolygonEditor(thiz.map, thiz.polygon);
        // 开启编辑模式
        thiz.polygonEditor.open();
        thiz.polygonEditor.on('end', thiz.onPolygonSave);
      });
    },
    addPolygon(AMap) {
      // 多边形轮廓线的节点坐标数组
      var paths = this.polygons.map(p => {
        return new AMap.LngLat(p.longitude, p.latitude);
      });
      return new AMap.Polygon({
        path: paths,
        fillColor: '#063677',
        // 多边形填充颜色
        fillOpacity: 0.2,
        borderWeight: 2,
        // 线条宽度，默认为 1
        strokeColor: '#063677' // 线条颜色
      });
    },
    generateMark(AMap) {
      let icon = new AMap.Icon({
        size: new AMap.Size(40, 50),
        // 图标尺寸
        image: 'https://cdn.waityou.online/ebf3c80a-243a-74c3-09c9-56bbfae16e13.png',
        // Icon的图像
        imageSize: new AMap.Size(40, 50) // 根据所设置的大小拉伸或压缩图片
      });
      let cfg = {
        position: new AMap.LngLat(this.longitude, this.latitude),
        'icon': icon,
        clickable: true
      };
      // cfg.label = {
      //   // content: '当前站点',
      //   direction: 'top'
      // };
      this.locationMarker = new AMap.Marker(cfg);
      return this.locationMarker;
    },
    onMapClick() {
      // let newLocation = [event.lnglat.lng, event.lnglat.lat];
      // this.locationMarker.setPosition(newLocation);
      // this.locationMarker.setLabel({
      //   content: event.lnglat.lng + ',' + event.lnglat.lat,
      //   direction: 'top'
      // });
      // this.$emit('onPickerPosition', newLocation)
    }
  },
  mounted() {
    this.init();
  }
};