import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "wbg pd16"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "content"
};
const _hoisted_4 = {
  class: "content"
};
const _hoisted_5 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_QuestionCreateView = _resolveComponent("QuestionCreateView");
  const _component_DialogCustomView = _resolveComponent("DialogCustomView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createVNode(_component_el_row, {
    class: "wd100"
  }, {
    default: _withCtx(() => [_createVNode(_component_BatterTableDataView, {
      "table-id": "9",
      "init-query": $data.initQuery,
      onOnEditData: $options.onEditData,
      onAddMore: $options.onCreateMore
    }, {
      type: _withCtx(data => [_createTextVNode(_toDisplayString(data.mapping[data.rowData]), 1)]),
      difficultyType: _withCtx(data => [_createTextVNode(_toDisplayString(data.mapping[data.rowData]), 1)]),
      expand: _withCtx(data => [_createVNode(_component_el_row, {
        class: "expand leftCenter"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 2,
          class: "leftCenter"
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("span", null, "    题干：", -1)])),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 22
        }, {
          default: _withCtx(() => [_createElementVNode("span", _hoisted_2, _toDisplayString(data.data.description), 1)]),
          _: 2
        }, 1024)]),
        _: 2
      }, 1024), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(JSON.parse(data.data.options), (op, opi) => {
        return _openBlock(), _createBlock(_component_el_row, {
          class: "expand leftCenter",
          key: opi
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 2
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("span", null, " ", -1)])),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 22
          }, {
            default: _withCtx(() => [_createElementVNode("span", _hoisted_3, _toDisplayString($data.questionOptions[opi]) + "、" + _toDisplayString(op), 1)]),
            _: 2
          }, 1024)]),
          _: 2
        }, 1024);
      }), 128)), _createVNode(_component_el_row, {
        class: "expand leftCenter"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 2
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("span", null, "    答案：", -1)])),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 22
        }, {
          default: _withCtx(() => [_createElementVNode("span", _hoisted_4, _toDisplayString(JSON.parse(data.data.answers).join('、')), 1)]),
          _: 2
        }, 1024)]),
        _: 2
      }, 1024), _createVNode(_component_el_row, {
        class: "expand leftCenter"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 2
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("span", null, "    解析：", -1)])),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 22
        }, {
          default: _withCtx(() => [_createElementVNode("span", _hoisted_5, _toDisplayString(data.data.analysis ? data.data.analysis : '-'), 1)]),
          _: 2
        }, 1024)]),
        _: 2
      }, 1024)]),
      _: 1
    }, 8, ["init-query", "onOnEditData", "onAddMore"])]),
    _: 1
  })]), _createVNode(_component_DialogCustomView, {
    visibleControl: $data.createVisible,
    title: "新建试题",
    onClose: _cache[0] || (_cache[0] = $event => $data.createVisible = false)
  }, {
    dialog: _withCtx(() => [_createVNode(_component_QuestionCreateView, {
      onSubmit: $options.createQuestion
    }, null, 8, ["onSubmit"])]),
    _: 1
  }, 8, ["visibleControl"]), _createVNode(_component_DialogCustomView, {
    visibleControl: $data.editVisible,
    title: "编辑试题",
    onClose: _cache[1] || (_cache[1] = $event => $data.editVisible = false)
  }, {
    dialog: _withCtx(() => [_createVNode(_component_QuestionCreateView, {
      "update-id": $data.updateId,
      onSubmit: $options.updateQuestion
    }, null, 8, ["update-id", "onSubmit"])]),
    _: 1
  }, 8, ["visibleControl"])], 64);
}