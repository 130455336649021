import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "ConsultListView",
  components: {
    BreadCrumb
  },
  data() {
    return {
      crumb: [{
        name: '咨询管理'
      }]
    };
  }
};