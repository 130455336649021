import StationVisitTpl from "@/components/station/StationVisitTpl";
import api from "@/api";
export default {
  name: "StationVisitTemplateManageView",
  components: {
    StationVisitTpl
  },
  data() {
    return {
      stationId: this.$route.params.stationId,
      tplVersion: 0,
      morning: 0,
      calendar: null,
      monthDate: null
    };
  },
  methods: {
    onDateUpdate(d) {
      this.monthDate = d;
    },
    deleteTpl(tid) {
      api.deleteStationVisitTpls({
        stationId: this.stationId,
        firstDay: this.monthDate,
        type: tid
      }).then(() => {
        this.tplVersion++;
      });
    },
    setMorning() {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      this.morning = now.getTime();
    },
    init() {
      this.setMorning();
    }
  },
  mounted() {
    this.init();
  }
};