import VisitComment from "@/components/visit/VisitComment";
import api from "@/api";
import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "DrillDetailView",
  components: {
    BreadCrumb,
    VisitComment
  },
  data() {
    return {
      crumb: [{
        name: '演练管理',
        link: '/drill/list'
      }, {
        name: '演练详情'
      }],
      stateMap: {
        INIT: "拼团中",
        COMMIT: "待审核",
        CANCEL: "已取消",
        PASS: "待参观",
        PROCESSING: "参观中",
        FINISH: "参观完成"
      },
      uploaderVersion: 0,
      dateId: this.$route.params.dateId,
      drill: {},
      comments: [],
      spots: []
    };
  },
  methods: {
    enableComment(cmm) {
      api.updateEvalutionVisible(cmm.id, true).then(() => {
        this.init();
      });
    },
    disEnableComment(cmm) {
      api.updateEvalutionVisible(cmm.id, false).then(() => {
        this.init();
      });
    },
    startDrill() {
      api.setDrillState({
        id: this.dateId,
        state: 'PROCESSING'
      }).then(() => {
        this.init();
      });
    },
    finishDrill() {
      api.setDrillState({
        id: this.dateId,
        state: 'FINISH'
      }).then(() => {
        this.init();
      });
    },
    onUploaded(url) {
      api.addSpotImage({
        drillId: this.dateId,
        image: url
      }).then(() => {
        this.uploaderVersion++;
        this.init();
      });
    },
    init() {
      api.getDrillDate(this.dateId).then(ret => {
        this.drill = ret.drill;
        this.comments = ret.comments;
        this.spots = ret.spots;
      });
    }
  },
  mounted() {
    this.init();
  }
};