import BatterTableDataView from "@/components/BatterTableDataView";
import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "DrillTopicListView",
  components: {
    BatterTableDataView,
    BreadCrumb
  },
  data() {
    return {
      crumb: [{
        name: '短信管理'
      }]
    };
  },
  methods: {}
};