import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "wbg pd16"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_el_row = _resolveComponent("el-row");
  const _component_DialogCustomView = _resolveComponent("DialogCustomView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createVNode(_component_el_row, {
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_BatterTableDataView, {
      "table-id": "7",
      onOnDetailData: $options.onDetailData
    }, {
      categoryType: _withCtx(data => [_createTextVNode(_toDisplayString(data.mapping[data.rowData]), 1)]),
      type: _withCtx(data => [_createTextVNode(_toDisplayString(data.mapping[data.rowData]), 1)]),
      _: 1
    }, 8, ["onOnDetailData"])]),
    _: 1
  })]), _createVNode(_component_DialogCustomView, {
    title: "查看资料",
    width: "40%",
    onOnDialogClose: _cache[0] || (_cache[0] = $event => $data.previewVisible = false),
    "visible-control": $data.previewVisible
  }, {
    dialog: _withCtx(() => [$data.previewVisible && $data.previewSrc.endsWith('mp4') ? (_openBlock(), _createElementBlock("video", {
      key: 0,
      style: {
        "width": "100%"
      },
      controls: "",
      src: $data.previewSrc
    }, null, 8, _hoisted_2)) : (_openBlock(), _createElementBlock("img", {
      key: 1,
      src: $data.previewSrc,
      style: {
        "width": "100%"
      }
    }, null, 8, _hoisted_3))]),
    _: 1
  }, 8, ["visible-control"])], 64);
}