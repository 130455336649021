import "core-js/modules/es.array.push.js";
import { ElMessage } from "element-plus";
import api from "@/api";
export default {
  name: "VisitTplEditor",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      visitDates: [],
      tpl: {
        templateName: '',
        minPeopleCount: 20,
        maxPeopleCount: 40,
        visitDateType: null,
        visitGroup: null,
        visitContainHolidayType: null,
        visitTimeList: [],
        visitWeekType: []
      },
      startTime: null,
      endTime: null,
      week: null,
      visitDateType: {
        ALL_MONTH: '0',
        MONTH_SINGLE_WEEK: '1',
        MONTH_DOUBLE_WEEK: '2'
      }
    };
  },
  methods: {
    save() {
      this.tpl.visitContainHolidayType = this.visitDates.includes('2') ? 0 : 1;
      if (this.visitDates.includes('0')) {
        this.tpl.visitWeekType.push('SAT');
      }
      if (this.visitDates.includes('1')) {
        this.tpl.visitWeekType.push('SUN');
      }
      if (this.data.id) {
        api.updateVisitTpl(this.tpl).then(() => {
          this.$emit('close');
        });
      } else {
        api.createVisitTpl(this.tpl).then(() => {
          this.$emit('close');
        });
      }
    },
    deleteVisitTime(idx) {
      this.tpl.visitTimeList.splice(idx, 1);
    },
    addVisitTime() {
      if (!this.startTime || !this.endTime || this.visitDates.length < 1) {
        ElMessage.error('请先选择参观日期和时段');
        return;
      }
      if (this.week == 0 || this.week == 1) {
        this.tpl.visitTimeList.push({
          startTime: this.formatDate2HHMM(this.startTime),
          endTime: this.formatDate2HHMM(this.endTime),
          week: 6
        });
      }
      if (this.week == 0 || this.week == 2) {
        this.tpl.visitTimeList.push({
          startTime: this.formatDate2HHMM(this.startTime),
          endTime: this.formatDate2HHMM(this.endTime),
          week: 7
        });
      }
    },
    formatDate2HHMM(d) {
      return d.getHours().toString().padStart(2, '0') + ':' + d.getMinutes().toString().padStart(2, '0');
    },
    init() {
      if (!this.data.id) {
        return;
      }
      this.tpl = this.data;
      this.tpl.visitGroup = this.data.visitGroup == 'ALLOW_GROUP' ? '0' : '1';
      this.tpl.visitDateType = this.visitDateType[this.data.visitDateType];
      if (this.data.visitWeekType.includes('SAT')) {
        this.visitDates.push('0');
      }
      if (this.data.visitWeekType.includes('SUN')) {
        this.visitDates.push('1');
      }
      if (this.data.visitContainHolidayType == 'NOT_CONTAIN_HOLIDAY') {
        this.visitDates.push('2');
      }
    }
  },
  mounted() {
    this.init();
  }
};