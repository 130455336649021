import BatterTableDataView from "@/components/BatterTableDataView";
import api from "@/api";
import BreadCrumb from "@/components/BreadCrumb";
import DialogView from "@/components/DialogView.vue";
export default {
  name: "TrainListView",
  components: {
    DialogView,
    BreadCrumb,
    BatterTableDataView
  },
  data() {
    return {
      auditVisible: false,
      auditId: null,
      crumb: [{
        name: '培训管理'
      }]
    };
  },
  methods: {
    submitAudit(data) {
      data.id = this.auditId;
      api.submitTrainAudit(data).then(() => {
        this.auditVisible = false;
        this.$refs.trainTable.fetchData();
      });
    },
    onDetailData(data) {
      window.open('/#/train/detail/' + data.id);
    },
    audit(dateId) {
      this.auditId = dateId;
      this.auditVisible = true;
    }
  }
};