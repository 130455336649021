import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createVNode as _createVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  style: {
    "display": "flex",
    "flex-direction": "column"
  }
};
const _hoisted_2 = {
  class: "queryFormElement"
};
const _hoisted_3 = {
  key: 0,
  class: "formLabelText"
};
const _hoisted_4 = {
  class: "queryFormElement center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_cascader = _resolveComponent("el-cascader");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_date_picker = _resolveComponent("el-date-picker");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.form, (f, i) => {
      return _openBlock(), _createBlock(_component_el_col, {
        span: $data.colWidth,
        key: i,
        class: "queryFormWrap"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [f.type !== 'placeholder' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString(f.label) + "：", 1)])) : _createCommentVNode("", true), f.type === 'select' ? (_openBlock(), _createBlock(_component_el_select, {
          key: 1,
          onChange: $event => $options.queryDataSource(f.name),
          modelValue: $data.query[f.name],
          "onUpdate:modelValue": $event => $data.query[f.name] = $event,
          class: "formElement",
          placeholder: f.placeholder ? f.placeholder : '请选择'
        }, {
          default: _withCtx(() => [f.dataSource ? (_openBlock(true), _createElementBlock(_Fragment, {
            key: 0
          }, _renderList($data.dataSource[f.name], item => {
            return _openBlock(), _createBlock(_component_el_option, {
              key: item.value,
              label: item.label,
              value: item.value
            }, null, 8, ["label", "value"]);
          }), 128)) : (_openBlock(true), _createElementBlock(_Fragment, {
            key: 1
          }, _renderList(f.value, item => {
            return _openBlock(), _createBlock(_component_el_option, {
              key: item.value,
              label: item.label,
              value: item.value
            }, null, 8, ["label", "value"]);
          }), 128))]),
          _: 2
        }, 1032, ["onChange", "modelValue", "onUpdate:modelValue", "placeholder"])) : _createCommentVNode("", true), f.type === 'cascader' && !f.dataSource ? (_openBlock(), _createBlock(_component_el_cascader, {
          key: 2,
          modelValue: $data.query[f.name],
          "onUpdate:modelValue": $event => $data.query[f.name] = $event,
          placeholder: "请选择",
          style: {
            "width": "240px"
          },
          props: {
            emitPath: false
          },
          options: f.value,
          "show-all-levels": false
        }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])) : _createCommentVNode("", true), f.type === 'cascader' && f.dataSource ? (_openBlock(), _createBlock(_component_el_cascader, {
          key: 3,
          onChange: v => {
            this.query[f.name] = v;
            $options.queryDataSource(f.name);
          },
          placeholder: "请选择",
          style: {
            "width": "240px"
          },
          props: {
            emitPath: false,
            lazy: true,
            checkStrictly: f.checkStrictly,
            lazyLoad: (n, s) => $options.fnLazyLoad(f.name, f.dataSource, n, s)
          },
          "show-all-levels": false
        }, null, 8, ["onChange", "props"])) : _createCommentVNode("", true), f.type === 'input' ? (_openBlock(), _createBlock(_component_el_input, {
          key: 4,
          modelValue: $data.query[f.name],
          "onUpdate:modelValue": $event => $data.query[f.name] = $event,
          class: "formElement",
          "suffix-icon": f.suffixIcon,
          "prefix-icon": f.prefixIcon,
          placeholder: f.placeholder
        }, null, 8, ["modelValue", "onUpdate:modelValue", "suffix-icon", "prefix-icon", "placeholder"])) : _createCommentVNode("", true), f.type === 'datepicker' ? (_openBlock(), _createBlock(_component_el_date_picker, {
          key: 5,
          modelValue: $data.query[f.name],
          "onUpdate:modelValue": $event => $data.query[f.name] = $event,
          "value-format": "YYYY-MM-DD",
          style: _normalizeStyle([{
            "width": "240px"
          }, f.pickerType == 'date' ? 'width: 240px' : 'width:220px']),
          type: f.pickerType,
          placeholder: f.placeholder
        }, null, 8, ["modelValue", "onUpdate:modelValue", "style", "type", "placeholder"])) : _createCommentVNode("", true)])]),
        _: 2
      }, 1032, ["span"]);
    }), 128))]),
    _: 1
  }), !$props.hideAllBtns ? (_openBlock(), _createBlock(_component_el_row, {
    key: 0
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "beforeButtons", {
      addButton: $data.addButton
    }, undefined, true), _createVNode(_component_el_col, {
      span: $props.buttonAreaWidth,
      class: "queryFormWrap"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_button, {
        class: "primaryBtn",
        onClick: $options.submit,
        icon: 'Search'
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("查询")])),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_el_button, {
        icon: 'RefreshLeft',
        onClick: $options.onReset
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("重置")])),
        _: 1
      }, 8, ["onClick"]), $data.addButton ? (_openBlock(), _createBlock(_component_el_button, {
        key: 0,
        class: "primaryBtn",
        icon: 'plus',
        onClick: $options.addMore
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("添加")])),
        _: 1
      }, 8, ["onClick"])) : _createCommentVNode("", true)])]),
      _: 1
    }, 8, ["span"]), _renderSlot(_ctx.$slots, "afterButtons", {}, undefined, true)]),
    _: 3
  })) : _createCommentVNode("", true)]);
}