export default {
  name: "LeaderInfo",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};