import BreadCrumb from "@/components/BreadCrumb.vue";
import api from "@/api";
import { ElMessage } from "element-plus";
export default {
  name: "SystemSettingView",
  components: {
    BreadCrumb
  },
  data() {
    return {
      crumb: [{
        name: '系统设置'
      }],
      setting: {}
    };
  },
  methods: {
    update(k) {
      api.updateSysConf({
        confType: 'SYSTEM_SETTING',
        key: k,
        value: this.setting[k]
      }).then(() => {
        ElMessage.success('更新成功!');
      });
    },
    init() {
      const keys = ['SMS_SWITCH', 'SMS_DEBUG', 'CLOSE_VISIT_NOTICE'];
      keys.forEach(k => {
        api.getSysConf({
          confType: 'SYSTEM_SETTING',
          key: k
        }).then(ret => {
          this.setting[k] = ret === null || ret === void 0 ? void 0 : ret.value;
        });
      });
    }
  },
  mounted() {
    this.init();
  }
};