import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "LeaderInfoContainer"
};
const _hoisted_2 = {
  class: "pd12"
};
const _hoisted_3 = {
  class: "baseFont lightColor"
};
const _hoisted_4 = {
  class: "pd12"
};
const _hoisted_5 = {
  class: "baseFont lightColor"
};
const _hoisted_6 = {
  class: "pd12"
};
const _hoisted_7 = {
  class: "baseFont lightColor"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[3] || (_cache[3] = _createElementVNode("div", {
    class: "pd12"
  }, [_createElementVNode("span", {
    class: "bigFont boldFont"
  }, "领队")], -1)), _cache[4] || (_cache[4] = _createElementVNode("div", {
    class: "divider"
  }, null, -1)), _createElementVNode("div", _hoisted_2, [_cache[0] || (_cache[0] = _createElementVNode("span", {
    class: "baseFont boldFont"
  }, " 领队姓名", -1)), _createElementVNode("span", _hoisted_3, "   " + _toDisplayString($props.data.userName), 1)]), _cache[5] || (_cache[5] = _createStaticVNode("<div class=\"divider\" data-v-3da2086c></div><div class=\"pd12\" data-v-3da2086c><span class=\"baseFont boldFont\" data-v-3da2086c>证件类型</span><span class=\"baseFont\" data-v-3da2086c>  中国居民身份证</span></div><div class=\"divider\" data-v-3da2086c></div>", 3)), _createElementVNode("div", _hoisted_4, [_cache[1] || (_cache[1] = _createElementVNode("span", {
    class: "baseFont boldFont"
  }, "证件号码", -1)), _createElementVNode("span", _hoisted_5, "  " + _toDisplayString($props.data.idCard), 1)]), _cache[6] || (_cache[6] = _createElementVNode("div", {
    class: "divider"
  }, null, -1)), _createElementVNode("div", _hoisted_6, [_cache[2] || (_cache[2] = _createElementVNode("span", {
    class: "baseFont boldFont"
  }, " 手机号码", -1)), _createElementVNode("span", _hoisted_7, "  " + _toDisplayString($props.data.phone), 1)])]);
}