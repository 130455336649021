import "core-js/modules/es.array.push.js";
export default {
  name: "StationNumberView",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      numberType: '0',
      maxValue: 0,
      values: [],
      colors: ['#4386FF', '#F98D39', '#3FD2E2', '#13BDA0', '#D95040']
    };
  },
  watch: {
    data() {
      this.init();
    }
  },
  methods: {
    init() {
      this.values = [];
      this.maxValue = 0;
      for (let s of this.data.sub) {
        let v = this.getStationValue(s);
        this.maxValue = Math.max(this.maxValue, v);
        this.values.push({
          name: s.name,
          value: v
        });
      }
      this.values.sort((a, b) => b.value - a.value);
    },
    getStationValue(s) {
      switch (this.numberType) {
        case "0":
          return s.visitCount;
        case "1":
          return s.visitPeopleCount;
        case "2":
          return s.browseCount;
        case "3":
          return s.likeCount;
      }
    }
  },
  mounted() {
    this.init();
  }
};