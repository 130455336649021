import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "KpiAddDialogContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_date_picker = _resolveComponent("el-date-picker");
  const _component_el_cascader = _resolveComponent("el-cascader");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "leftCenter"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 24,
      class: "leftCenter mb12"
    }, {
      default: _withCtx(() => [_cache[14] || (_cache[14] = _createElementVNode("div", {
        style: {
          "width": "100px"
        }
      }, [_createElementVNode("span", null, "标题：")], -1)), _createVNode(_component_el_input, {
        placeholder: "请输入标题",
        modelValue: $data.post.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.post.title = $event),
        style: {
          "width": "300px"
        }
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 24,
      class: "leftCenter mb12"
    }, {
      default: _withCtx(() => [_cache[15] || (_cache[15] = _createElementVNode("div", {
        style: {
          "width": "100px"
        }
      }, [_createElementVNode("span", null, "渠道：")], -1)), _createVNode(_component_el_select, {
        modelValue: $data.post.channelId,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.post.channelId = $event),
        style: {
          "width": "300px"
        },
        placeholder: "请选择"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.channels, item => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: item.value,
            label: item.label,
            value: item.value
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    }), $data.post.channelId == 2 ? (_openBlock(), _createBlock(_component_el_col, {
      key: 0,
      span: 24,
      class: "leftCenter mb12"
    }, {
      default: _withCtx(() => [_cache[16] || (_cache[16] = _createElementVNode("div", {
        style: {
          "width": "100px"
        }
      }, [_createElementVNode("span", null, "行政级别：")], -1)), _createVNode(_component_el_select, {
        modelValue: $data.post.govLevelId,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.post.govLevelId = $event),
        style: {
          "width": "300px"
        },
        placeholder: "请选择"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.govLevelIds, item => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: item.value,
            label: item.label,
            value: item.value
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    })) : _createCommentVNode("", true), $data.post.channelId == 2 || $data.post.channelId == 4 ? (_openBlock(), _createBlock(_component_el_col, {
      key: 1,
      span: 24,
      class: "leftCenter mb12"
    }, {
      default: _withCtx(() => [_cache[17] || (_cache[17] = _createElementVNode("div", {
        style: {
          "width": "100px"
        }
      }, [_createElementVNode("span", null, "策划选题：")], -1)), _createVNode(_component_el_select, {
        modelValue: $data.post.topicId,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.post.topicId = $event),
        style: {
          "width": "300px"
        },
        placeholder: "请选择"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.topicIds, item => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: item.value,
            label: item.label,
            value: item.value
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    })) : _createCommentVNode("", true), $data.post.channelId == 1 || $data.post.channelId == 3 ? (_openBlock(), _createBlock(_component_el_col, {
      key: 2,
      span: 24,
      class: "leftCenter mb12"
    }, {
      default: _withCtx(() => [_cache[18] || (_cache[18] = _createElementVNode("div", {
        style: {
          "width": "100px"
        }
      }, [_createElementVNode("span", null, "组织级别：")], -1)), _createVNode(_component_el_select, {
        modelValue: $data.post.orgLevelId,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.post.orgLevelId = $event),
        style: {
          "width": "300px"
        },
        placeholder: "请选择"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.orgLevelIds, item => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: item.value,
            label: item.label,
            value: item.value
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    })) : _createCommentVNode("", true), $data.post.channelId == 1 || $data.post.channelId == 3 ? (_openBlock(), _createBlock(_component_el_col, {
      key: 3,
      span: 24,
      class: "leftCenter mb12"
    }, {
      default: _withCtx(() => [_cache[19] || (_cache[19] = _createElementVNode("div", {
        style: {
          "width": "100px"
        }
      }, [_createElementVNode("span", null, "原创/素材：")], -1)), _createVNode(_component_el_select, {
        modelValue: $data.post.type,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $data.post.type = $event),
        style: {
          "width": "300px"
        },
        placeholder: "请选择"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_option, {
          label: "原创",
          value: "0"
        }), _createVNode(_component_el_option, {
          label: "素材",
          value: "1"
        })]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    })) : _createCommentVNode("", true), $data.post.channelId == 3 ? (_openBlock(), _createBlock(_component_el_col, {
      key: 4,
      span: 24,
      class: "leftCenter mb12"
    }, {
      default: _withCtx(() => [_cache[20] || (_cache[20] = _createElementVNode("div", {
        style: {
          "width": "100px"
        }
      }, [_createElementVNode("span", null, "平台：")], -1)), _createVNode(_component_el_select, {
        modelValue: $data.post.platformId,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => $data.post.platformId = $event),
        style: {
          "width": "300px"
        },
        placeholder: "请选择"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.platformIds, item => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: item.value,
            label: item.label,
            value: item.value
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    })) : _createCommentVNode("", true), $data.post.channelId == 3 ? (_openBlock(), _createBlock(_component_el_col, {
      key: 5,
      span: 24,
      class: "leftCenter mb12"
    }, {
      default: _withCtx(() => [_cache[21] || (_cache[21] = _createElementVNode("div", {
        style: {
          "width": "100px"
        }
      }, [_createElementVNode("span", null, "浏览量：")], -1)), _createVNode(_component_el_input, {
        placeholder: "请输入浏览量",
        type: "number",
        modelValue: $data.post.viewCnt,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => $data.post.viewCnt = $event),
        style: {
          "width": "300px"
        }
      }, null, 8, ["modelValue"])]),
      _: 1
    })) : _createCommentVNode("", true), _createVNode(_component_el_col, {
      span: 24,
      class: "leftCenter mb12"
    }, {
      default: _withCtx(() => [_cache[22] || (_cache[22] = _createElementVNode("div", {
        style: {
          "width": "100px"
        }
      }, [_createElementVNode("span", null, "链接：")], -1)), _createVNode(_component_el_input, {
        placeholder: "请输入链接",
        modelValue: $data.post.link,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => $data.post.link = $event),
        style: {
          "width": "300px"
        }
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 24,
      class: "leftCenter mb12"
    }, {
      default: _withCtx(() => [_cache[23] || (_cache[23] = _createElementVNode("div", {
        style: {
          "width": "100px"
        }
      }, [_createElementVNode("span", null, "发布机构：")], -1)), _createVNode(_component_el_input, {
        placeholder: "请输入",
        modelValue: $data.post.pubInst,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => $data.post.pubInst = $event),
        style: {
          "width": "300px"
        }
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 24,
      class: "leftCenter mb12"
    }, {
      default: _withCtx(() => [_cache[24] || (_cache[24] = _createElementVNode("div", {
        style: {
          "width": "100px"
        }
      }, [_createElementVNode("span", null, "发布人：")], -1)), _createVNode(_component_el_input, {
        placeholder: "请输入",
        modelValue: $data.post.pubUserName,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => $data.post.pubUserName = $event),
        style: {
          "width": "300px"
        }
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 24,
      class: "leftCenter mb12"
    }, {
      default: _withCtx(() => [_cache[25] || (_cache[25] = _createElementVNode("div", {
        style: {
          "width": "100px"
        }
      }, [_createElementVNode("span", null, "发布时间：")], -1)), _createVNode(_component_el_date_picker, {
        style: {
          "width": "310px"
        },
        modelValue: $data.post.pubDate,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => $data.post.pubDate = $event),
        type: "date",
        placeholder: "选择日期",
        "value-format": "YYYY-MM-DD"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 24,
      class: "leftCenter mb12"
    }, {
      default: _withCtx(() => [_cache[26] || (_cache[26] = _createElementVNode("div", {
        style: {
          "width": "100px"
        }
      }, [_createElementVNode("span", null, "宣传员：")], -1)), _createVNode(_component_el_cascader, {
        modelValue: $data.propagandistId,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => $data.propagandistId = $event),
        style: {
          "width": "300px"
        },
        placeholder: "请选择",
        options: $data.propagandistIds,
        onChange: $options.handlePropagandistIdChange
      }, null, 8, ["modelValue", "options", "onChange"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 24,
      class: "bothCenter",
      style: {
        "margin-top": "16px"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        style: {
          "width": "150px"
        },
        onClick: _cache[13] || (_cache[13] = $event => _ctx.$emit('close'))
      }, {
        default: _withCtx(() => _cache[27] || (_cache[27] = [_createTextVNode("取消")])),
        _: 1
      }), _createVNode(_component_el_button, {
        type: "primary",
        style: {
          "width": "150px"
        },
        onClick: $options.confirm
      }, {
        default: _withCtx(() => _cache[28] || (_cache[28] = [_createTextVNode("确认")])),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  })]);
}