import BreadCrumb from "@/components/BreadCrumb";
import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "StudyDataView",
  components: {
    BatterTableDataView,
    BreadCrumb
  },
  data() {
    return {
      previewVisible: false,
      previewSrc: null,
      crumb: [{
        name: '学习资料'
      }]
    };
  },
  methods: {
    onDetailData(data) {
      this.previewSrc = data.dataUrl;
      this.previewVisible = true;
    }
  }
};