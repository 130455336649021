import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "SystemSettingViewContainer pd16"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createVNode(_component_el_row, {
    class: "leftCenter"
  }, {
    default: _withCtx(() => [_cache[7] || (_cache[7] = _createElementVNode("span", null, "短信接口配置(ON开启 OFF关闭 DEBUG调试)：", -1)), _createVNode(_component_el_input, {
      modelValue: $data.setting.SMS_SWITCH,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.setting.SMS_SWITCH = $event),
      style: {
        "width": "300px"
      }
    }, null, 8, ["modelValue"]), _createVNode(_component_el_button, {
      type: "primary",
      class: "ml32",
      onClick: _cache[1] || (_cache[1] = $event => $options.update('SMS_SWITCH'))
    }, {
      default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("更新")])),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_row, {
    class: "leftCenter mt16"
  }, {
    default: _withCtx(() => [_cache[9] || (_cache[9] = _createElementVNode("span", null, "短信DEBUG手机号(转发短信用)：", -1)), _createVNode(_component_el_input, {
      modelValue: $data.setting.SMS_DEBUG,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.setting.SMS_DEBUG = $event),
      style: {
        "width": "300px"
      }
    }, null, 8, ["modelValue"]), _createVNode(_component_el_button, {
      type: "primary",
      class: "ml32",
      onClick: _cache[3] || (_cache[3] = $event => $options.update('SMS_DEBUG'))
    }, {
      default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("更新")])),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_row, {
    class: "leftCenter mt16"
  }, {
    default: _withCtx(() => [_cache[11] || (_cache[11] = _createElementVNode("span", null, "全站关闭预约提示设置：", -1)), _createVNode(_component_el_input, {
      modelValue: $data.setting.CLOSE_VISIT_NOTICE,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.setting.CLOSE_VISIT_NOTICE = $event),
      style: {
        "width": "300px"
      }
    }, null, 8, ["modelValue"]), _createVNode(_component_el_button, {
      type: "primary",
      class: "ml32",
      onClick: _cache[5] || (_cache[5] = $event => $options.update('CLOSE_VISIT_NOTICE'))
    }, {
      default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode("更新")])),
      _: 1
    })]),
    _: 1
  })]);
}