import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "wbg pd16"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_el_row = _resolveComponent("el-row");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createVNode(_component_el_row, {
    class: "wd100"
  }, {
    default: _withCtx(() => [_createVNode(_component_BatterTableDataView, {
      "table-id": "26",
      ref: "trainTable",
      onOnDetailData: $options.onDetailData
    }, {
      avatar: _withCtx(data => [_createElementVNode("img", {
        src: data.rowData,
        style: {
          "width": "60px"
        }
      }, null, 8, _hoisted_2)]),
      totalScore: _withCtx(data => [_createElementVNode("span", null, _toDisplayString(data.data.teachTimes < 1 ? '-' : (data.rowData * 100.0 / data.data.teachTimes).toFixed(2) + '%'), 1)]),
      rightButtons: _withCtx(() => [_createVNode(_component_el_row, {
        class: "rightCenter"
      }, {
        default: _withCtx(() => [_cache[0] || (_cache[0] = _createElementVNode("span", {
          class: "smallFont"
        }, "显示讲师列表：", -1)), _createVNode(_component_el_switch, {
          "model-value": $data.teacherListVisible,
          onChange: $options.updateTeachListVisible
        }, null, 8, ["model-value", "onChange"])]),
        _: 1
      })]),
      _: 1
    }, 8, ["onOnDetailData"])]),
    _: 1
  })]);
}