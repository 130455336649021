import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "background-color": "white"
  }
};
const _hoisted_2 = {
  class: "leftCenter"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_el_row = _resolveComponent("el-row");
  const _component_svg_icon = _resolveComponent("svg-icon");
  const _component_el_button = _resolveComponent("el-button");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_VisitTplEditor = _resolveComponent("VisitTplEditor");
  const _component_DialogCustomView = _resolveComponent("DialogCustomView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", {
      class: "pageTitle",
      style: {
        "color": "gray",
        "padding": "20px 0 20px 20px",
        "cursor": "pointer"
      },
      onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.back())
    }, "站点管理"), _cache[2] || (_cache[2] = _createElementVNode("span", {
      class: "pageTitle",
      style: {
        "color": "black"
      }
    }, "/参观模板", -1))]), _createVNode(_component_el_divider, {
      class: "padding0 margin0"
    })]),
    _: 1
  }), _createVNode(_component_el_row, {
    style: {
      "padding": "20px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_button, {
      style: {
        "background-color": "#005FF5",
        "color": "white"
      },
      onClick: _cache[1] || (_cache[1] = $event => $data.createVisible = true)
    }, {
      default: _withCtx(() => [_createVNode(_component_svg_icon, {
        "icon-class": "add"
      }), _cache[3] || (_cache[3] = _createTextVNode("  添加 "))]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_row, {
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_BatterTableDataView, {
      "table-id": "2",
      ref: "visitTplTable",
      onOnEditData: $options.onEditData
    }, {
      groupSizeLimit: _withCtx(data => [_createTextVNode(_toDisplayString(data.data.minPeopleCount) + "/" + _toDisplayString(data.data.maxPeopleCount), 1)]),
      visitGroup: _withCtx(data => [_createTextVNode(_toDisplayString(data.rowData == 'ALLOW_GROUP' ? '是' : '否'), 1)]),
      monthOpenTimes: _withCtx(data => [_createTextVNode(_toDisplayString((data.data.visitDateType == 'ALL_MONTH' ? 4 : 2) * data.data.visitTimeList.length), 1)]),
      _: 1
    }, 8, ["onOnEditData"])]),
    _: 1
  })]), _createVNode(_component_DialogCustomView, {
    "visible-control": $data.createVisible,
    title: "添加参观模板",
    onClose: $options.onClose
  }, {
    default: _withCtx(() => [$data.createVisible ? (_openBlock(), _createBlock(_component_VisitTplEditor, {
      key: 0,
      data: $data.editObj,
      onClose: $options.onClose
    }, null, 8, ["data", "onClose"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["visible-control", "onClose"])], 64);
}