import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  style: {
    "border": "#CDDBF7 1px solid"
  },
  class: "brd8 wd100"
};
const _hoisted_2 = {
  class: "opd16 vpd14 leftCenter"
};
const _hoisted_3 = {
  class: "baseFont boldFont"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_StationInfoCard = _resolveComponent("StationInfoCard");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, _toDisplayString($data.superStation.name) + "(" + _toDisplayString($data.stations.length) + ")", 1)]), _cache[0] || (_cache[0] = _createElementVNode("div", {
    style: {
      "height": "1px",
      "background-color": "#CDDBF7",
      "width": "100%"
    }
  }, null, -1)), _createVNode(_component_el_row, {
    class: "pd16 leftCenter stationList",
    gutter: 16
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.stations, s => {
      return _openBlock(), _createBlock(_component_el_col, {
        span: 12,
        class: "mb16",
        key: s.id
      }, {
        default: _withCtx(() => [_createVNode(_component_StationInfoCard, {
          data: s
        }, null, 8, ["data"])]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  })]);
}