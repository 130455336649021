import BatterTableDataView from "@/components/BatterTableDataView";
import api from "@/api";
import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "VisitListView",
  components: {
    BreadCrumb,
    BatterTableDataView
  },
  data() {
    return {
      switches: {},
      crumb: [{
        name: '参观评价'
      }]
    };
  },
  methods: {
    updateEnableState(id, val) {
      api.updateEvalutionVisible(id, val).then(() => {
        this.$refs.visitEvaTable.fetchData();
      });
    },
    onFetchData(data) {
      let list = data['list'];
      for (let l of list) {
        this.switches[l['id']] = l['visible'];
      }
    }
  }
};