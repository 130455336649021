import * as echarts from "echarts";
export default {
  name: "VerticalBarChart",
  data() {
    return {};
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    barData() {
      this.$nextTick(() => this.renderChart());
    }
  },
  mounted() {
    this.renderChart();
  },
  unmounted() {},
  methods: {
    onChartClicked(e) {
      console.log('onChartClicked', e);
      if (e.componentSubType == 'bar') {
        this.$emit('stationClick', this.data[e.dataIndex]);
      }
    },
    resize() {
      this.$nextTick(() => this.renderChart());
    },
    renderChart() {
      let option = {
        grid: {
          bottom: 20,
          left: 60,
          right: 60,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['参观次数', '参观人次']
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center'
        },
        xAxis: [{
          type: 'category',
          axisTick: {
            show: false
          },
          data: this.data.map(s => s.name)
        }],
        yAxis: [{
          type: 'value'
        }],
        series: [{
          name: '参观次数',
          type: 'bar',
          barGap: 0,
          emphasis: {
            focus: 'series'
          },
          data: this.data.map(s => s.visitCount),
          itemStyle: {
            color: '#5087EC'
          }
        }, {
          name: '参观人次',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          data: this.data.map(s => s.visitPeopleCount),
          itemStyle: {
            color: '#68BBC4'
          }
        }]
      };
      let bar = document.getElementById("canvas");
      let exist = echarts.getInstanceByDom(bar);
      if (exist) {
        console.log('canvas', '销毁已存在的');
        exist.dispose();
      }
      let chart = echarts.init(bar);
      chart.setOption(option);
      chart.on('click', this.onChartClicked);
    }
  }
};