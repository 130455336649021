import api from "@/api";
import DialogView from "@/components/DialogView.vue";
export default {
  name: "StationVisitTpl",
  components: {
    DialogView
  },
  props: {
    stationId: {
      type: Number,
      default: 0
    },
    date: {
      type: String,
      default: null
    },
    addBtn: {
      type: Boolean,
      default: false
    },
    version: {
      type: Number,
      default: 0
    }
  },
  watch: {
    version() {
      this.init();
    },
    date() {
      this.init();
    }
  },
  data() {
    return {
      list: [],
      editVisible: false,
      createVisible: false,
      selectedDate: null,
      morning: 0,
      editId: null
    };
  },
  methods: {
    edit(d) {
      this.editId = d;
      this.editVisible = true;
    },
    deleteTpl(tplId) {
      api.deleteStationVisitTpl(tplId).then(() => {
        this.createVisible = false;
        this.init();
      });
    },
    createTpl(data) {
      data.date = this.date;
      data.stationId = this.stationId;
      api.createStationVisitTpl(data).then(() => {
        this.createVisible = false;
        // this.init()
        this.$emit('fresh');
      });
    },
    updateTpl(data) {
      data.id = this.editId;
      api.updateStationVisitTpl(data).then(() => {
        this.editVisible = false;
        // this.init()
        this.$emit('fresh');
      });
    },
    addVisitTemplate() {
      this.createVisible = true;
    },
    init() {
      this.$emit('dateUpdate', this.date);
      api.getStationVisitTemplate({
        stationId: this.stationId,
        date: this.date
      }).then(ret => {
        this.list = ret;
      });
    }
  },
  mounted() {
    this.init();
  }
};