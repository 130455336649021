import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "wbg pd16"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createVNode(_component_el_row, {
    class: "wd100"
  }, {
    default: _withCtx(() => [_createVNode(_component_BatterTableDataView, {
      onOnFetchData: $options.onFetchData,
      "table-id": "15"
    }, {
      avatar: _withCtx(data => [_createElementVNode("img", {
        src: data.rowData,
        style: {
          "width": "60px"
        }
      }, null, 8, _hoisted_2)]),
      enable: _withCtx(data => [_createVNode(_component_el_switch, {
        "active-color": "#0078D7",
        "model-value": $data.enableMap[data.rowId],
        onChange: v => $options.enableChange(data.rowId, v)
      }, null, 8, ["model-value", "onChange"])]),
      _: 1
    }, 8, ["onOnFetchData"])]),
    _: 1
  })]);
}