import QuestionListItem from "@/components/question/QuestionListItem";
import api from "@/api";
export default {
  name: "FileImportReview",
  components: {
    QuestionListItem
  },
  props: {
    imported: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  watch: {
    imported() {
      this.reCount();
    }
  },
  methods: {
    onHardTypeChange() {
      for (let q of this.parsed) {
        q.difficultyType = this.hardType;
      }
    },
    reCount() {
      this.howMany = 0;
      this.wrongCnt = 0;
      this.selectCnt = 0;
      this.mSelectCnt = 0;
      this.judgeCnt = 0;
      for (let q of this.parsed) {
        this.howMany++;
        if (q.error) {
          this.wrongCnt++;
        }
        if (q.type === 'SELECT') {
          this.selectCnt++;
        } else if (q.type === 'MULTIPLE') {
          this.mSelectCnt++;
        } else if (q.type === 'JUDGE') {
          this.judgeCnt++;
        }
      }
    },
    remove(i) {
      this.parsed.splice(i, 1);
      this.reCount();
    },
    updateAnswer(idx, answers) {
      this.parsed[idx].answers = answers;
    },
    checkInput() {
      api.checkAndParseQuestion({
        text: this.fileContent
      }).then(ret => {
        this.parsed = ret;
        this.reCount();
      });
    }
  },
  data() {
    return {
      hardType: 'VERY_EASY',
      howMany: 0,
      wrongCnt: 0,
      selectCnt: 0,
      mSelectCnt: 0,
      judgeCnt: 0,
      fileContent: '',
      parsed: [],
      questionOptions: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'L', 'N'],
      qTypes: {
        SELECT: '单选题',
        MULTIPLE: '多选题',
        JUDGE: '判断题'
      }
    };
  },
  mounted() {
    let content = '';
    let qIndex = 1;
    for (let q of this.imported) {
      content += `${this.qTypes[q.type]}\n`;
      content += `${qIndex}、${q.description}\n`;
      for (let a in q.options) {
        content += `${this.questionOptions[a]}: ${q.options[a]}\n`;
      }
      content += `答案: ${q.answers.join(',')}\n`;
      content += `解析: ${q.analysis}\n`;
      content += `来源: ${q.source}\n\n`;
      qIndex += 1;
    }
    this.fileContent = content;
    this.checkInput();
  }
};