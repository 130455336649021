import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "StationVisitTemplateManageViewContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_popconfirm = _resolveComponent("el-popconfirm");
  const _component_StationVisitTpl = _resolveComponent("StationVisitTpl");
  const _component_el_calendar = _resolveComponent("el-calendar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "pd20"
  }, {
    default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("span", {
      class: "ft14"
    }, "站点管理/参观开放编辑", -1)])),
    _: 1
  }), _cache[9] || (_cache[9] = _createElementVNode("div", {
    class: "divider"
  }, null, -1)), _createVNode(_component_el_row, {
    class: "leftCenter pd16"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_popconfirm, {
      title: "确定清除本月吗",
      onConfirm: _cache[0] || (_cache[0] = $event => $options.deleteTpl(0))
    }, {
      reference: _withCtx(() => [_createVNode(_component_el_button, {
        type: "danger"
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("清除本月")])),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_popconfirm, {
      title: "确定清除本年吗",
      onConfirm: _cache[1] || (_cache[1] = $event => $options.deleteTpl(1))
    }, {
      reference: _withCtx(() => [_createVNode(_component_el_button, {
        type: "danger"
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("清除本年")])),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_popconfirm, {
      title: "确定清除所有吗",
      onConfirm: _cache[2] || (_cache[2] = $event => $options.deleteTpl(2))
    }, {
      reference: _withCtx(() => [_createVNode(_component_el_button, {
        type: "danger"
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("清除所有")])),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_calendar, {
    modelValue: $data.calendar,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.calendar = $event)
  }, {
    "date-cell": _withCtx(({
      data
    }) => [data.type == 'current-month' ? (_openBlock(), _createBlock(_component_StationVisitTpl, {
      key: 0,
      "station-id": $data.stationId,
      onFresh: _cache[3] || (_cache[3] = $event => $data.tplVersion++),
      version: $data.tplVersion,
      onDateUpdate: $options.onDateUpdate,
      date: data.day,
      "add-btn": data.date.getTime() >= $data.morning
    }, null, 8, ["station-id", "version", "onDateUpdate", "date", "add-btn"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["modelValue"])]);
}