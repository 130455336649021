export default {
  name: "VisitComment",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    init() {
      this.score = this.data.score;
    }
  },
  data() {
    return {
      score: 5
    };
  },
  mounted() {
    this.init();
  }
};