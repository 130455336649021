import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "SetPolygonViewContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[1] || (_cache[1] = _createElementVNode("div", {
    id: "amapContainer",
    style: {
      "width": "100%",
      "height": "500px"
    }
  }, null, -1)), _createVNode(_component_el_button, {
    style: {
      "margin-top": "16px"
    },
    class: "primaryBtn",
    onClick: $options.saveEditor
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("保存编辑")])),
    _: 1
  }, 8, ["onClick"])]);
}