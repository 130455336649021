import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "VisitStatisticViewContainer"
};
const _hoisted_2 = {
  class: "pd16"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_BreadCrumb, {
    "no-divider": "",
    data: $data.crumb
  }, null, 8, ["data"])]), _cache[0] || (_cache[0] = _createElementVNode("div", {
    class: "divider"
  }, null, -1)), _createVNode(_component_el_row, {
    class: "pd16"
  }, {
    default: _withCtx(() => [_createVNode(_component_BatterTableDataView, {
      "table-id": 27,
      "export-btn": ""
    })]),
    _: 1
  })]);
}