import "core-js/modules/es.array.push.js";
import BatterTableDataView from "@/components/BatterTableDataView";
import api from "@/api";
import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "TeacherListView",
  components: {
    BreadCrumb,
    BatterTableDataView
  },
  data() {
    return {
      teacherListVisible: false,
      auditVisible: false,
      auditId: null,
      crumb: [{
        name: '讲师管理'
      }]
    };
  },
  methods: {
    updateTeachListVisible(e) {
      api.updateTeachListVisible(e).then(() => {
        this.teacherListVisible = e;
      });
    },
    submitAudit(data) {
      data.id = this.auditId;
      api.submitTrainAudit(data).then(() => {
        this.auditVisible = false;
        this.$refs.trainTable.fetchData();
      });
    },
    onDetailData(data) {
      this.$router.push('/train/detail/' + data.id);
    },
    audit(dateId) {
      this.auditId = dateId;
      this.auditVisible = true;
    },
    init() {
      api.getTeacherListVisible().then(ret => {
        this.teacherListVisible = ret;
      });
    }
  },
  mounted() {
    this.init();
  }
};