export default {
  name: "BreadCrumb",
  props: {
    noDivider: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};