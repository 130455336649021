import StationInfoCard from "@/components/dashboard/StationInfoCard";
export default {
  name: "DashboardStations",
  components: {
    StationInfoCard
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      stations: [],
      superStation: {}
    };
  },
  methods: {
    init() {
      this.stations = this.data.sub;
      this.superStation = this.data.super;
    }
  },
  mounted() {
    this.init();
  }
};