import DialogView from "@/components/DialogView";
import BatterTableDataView from "@/components/BatterTableDataView";
import api from "@/api";
import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "QuestiopRepoTypeView",
  components: {
    DialogView,
    BatterTableDataView,
    BreadCrumb
  },
  data() {
    return {
      createVisible: false,
      initQuery: {},
      switches: {},
      crumb: [{
        name: '题库类型管理'
      }]
    };
  },
  methods: {
    createTopic(data) {
      api.createQuestionRepoTopic(data).then(() => {
        this.createVisible = false;
        this.initQuery = {
          'curTime': new Date()
        };
      });
    },
    updateEnableState(topicId) {
      api.updateQuestionRepoTopicEnableState(topicId).then(() => {
        this.initQuery = {
          'curTime': new Date()
        };
      });
    },
    onFetchData(data) {
      let list = data['list'];
      for (let l of list) {
        this.switches[l['id']] = l['enable'];
      }
    }
  }
};