import api from "@/api";
import BreadCrumb from "@/components/BreadCrumb";
import BatterTableDataView from "@/components/BatterTableDataView.vue";
export default {
  name: "TrainValuationListView",
  components: {
    BatterTableDataView,
    BreadCrumb
  },
  data() {
    return {
      switches: {},
      crumb: [{
        name: '培训评价'
      }]
    };
  },
  methods: {
    updateEnableState(id, val) {
      api.updateEvalutionVisible(id, val).then(() => {
        this.$refs.trainEvaTable.fetchData();
      });
    },
    onFetchData(data) {
      let list = data['list'];
      for (let l of list) {
        this.switches[l['id']] = l['visible'];
      }
    }
  }
};