import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "VisitDetailViewContainer"
};
const _hoisted_2 = {
  class: "pd16"
};
const _hoisted_3 = {
  style: {
    "color": "#015EF5"
  }
};
const _hoisted_4 = {
  class: "leftCenter"
};
const _hoisted_5 = {
  class: "colFlex pl16"
};
const _hoisted_6 = {
  class: "smallFont lightColor mt4"
};
const _hoisted_7 = {
  class: "colFlex mt4"
};
const _hoisted_8 = {
  class: "ft14 lightColor"
};
const _hoisted_9 = {
  class: "leftCenter"
};
const _hoisted_10 = {
  class: "colFlex pl16"
};
const _hoisted_11 = {
  class: "smallFont lightColor mt4"
};
const _hoisted_12 = {
  class: "lightColor mt4 ft14"
};
const _hoisted_13 = {
  class: "ft14"
};
const _hoisted_14 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_popconfirm = _resolveComponent("el-popconfirm");
  const _component_el_row = _resolveComponent("el-row");
  const _component_TeamInfo = _resolveComponent("TeamInfo");
  const _component_LeaderInfo = _resolveComponent("LeaderInfo");
  const _component_VisitComment = _resolveComponent("VisitComment");
  const _component_el_image = _resolveComponent("el-image");
  const _component_el_tag = _resolveComponent("el-tag");
  const _component_DialogView = _resolveComponent("DialogView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_BreadCrumb, {
    "no-divider": "",
    data: $data.crumb
  }, null, 8, ["data"])]), _cache[12] || (_cache[12] = _createElementVNode("div", {
    class: "divider"
  }, null, -1)), _createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 16,
      class: "colFlex pd20",
      style: {
        "border-right": "#CCCCCC 1px solid"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_row, {
        class: "bothCenter mb16"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 12,
          class: "leftCenter"
        }, {
          default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString($data.visit.stationName) + " - 参观时间: " + _toDisplayString($data.visit.bookingDate) + " " + _toDisplayString($data.visit.bookingDateTime) + " - " + _toDisplayString($data.visit.finishDateTime), 1)]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 12,
          class: "rightCenter"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_popconfirm, {
            title: "现在就标记参观开始吗？系统会在开始参观时间自动变更状态",
            onConfirm: $options.startVisit
          }, {
            reference: _withCtx(() => {
              var _$data$visit;
              return [((_$data$visit = $data.visit) === null || _$data$visit === void 0 ? void 0 : _$data$visit.state) == 'PASS' ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                class: "mr8"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("参观开始")])),
                _: 1
              })) : _createCommentVNode("", true)];
            }),
            _: 1
          }, 8, ["onConfirm"]), _createVNode(_component_el_popconfirm, {
            title: "现在就标记参观完成吗？系统会在参观结束时间自动变更状态",
            onConfirm: $options.finishVisit
          }, {
            reference: _withCtx(() => {
              var _$data$visit2;
              return [((_$data$visit2 = $data.visit) === null || _$data$visit2 === void 0 ? void 0 : _$data$visit2.state) == 'PROCESSING' ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                class: "mr8"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("参观完成")])),
                _: 1
              })) : _createCommentVNode("", true)];
            }),
            _: 1
          }, 8, ["onConfirm"]), _createElementVNode("span", _hoisted_3, _toDisplayString($data.stateMap[$data.visit.state]), 1)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_row, {
        class: "bothCenter mb16"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 12,
          class: "bothCenter"
        }, {
          default: _withCtx(() => [_createVNode(_component_TeamInfo, {
            class: "brd8 wd100",
            data: $data.visit
          }, null, 8, ["data"])]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 12,
          class: "bothCenter"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("   ")])),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_row, {
        class: "leftCenter"
      }, {
        default: _withCtx(() => {
          var _$data$visit3;
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_$data$visit3 = $data.visit) === null || _$data$visit3 === void 0 ? void 0 : _$data$visit3.members, (m, mi) => {
            return _openBlock(), _createBlock(_component_el_col, {
              span: 12,
              class: "bothCenter",
              key: mi
            }, {
              default: _withCtx(() => [_createVNode(_component_LeaderInfo, {
                class: "brd8 wd100 mb8",
                data: m
              }, null, 8, ["data"])]),
              _: 2
            }, 1024);
          }), 128))];
        }),
        _: 1
      }), _cache[6] || (_cache[6] = _createElementVNode("span", {
        class: "baseFont mb16"
      }, "评价内容:", -1)), _createVNode(_component_el_row, {
        gutter: 10
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.comments, cmm => {
          return _openBlock(), _createBlock(_component_el_col, {
            span: 8,
            class: "bothCenter",
            key: cmm.id
          }, {
            default: _withCtx(() => [_createVNode(_component_VisitComment, {
              data: cmm,
              onEnableComment: $event => $options.enableComment(cmm),
              onDisEnableComment: $event => $options.disEnableComment(cmm)
            }, null, 8, ["data", "onEnableComment", "onDisEnableComment"])]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 8,
      class: "colFlex pd20"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.spots, sp => {
        return _openBlock(), _createElementBlock("div", {
          key: sp.id,
          style: {
            "display": "flex",
            "flex-direction": "column",
            "justify-content": "flex-start",
            "margin-bottom": "16px"
          }
        }, [sp.image.endsWith('mp4') ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_image, {
          onClick: $event => $options.previewVideo(sp.image),
          src: sp.image + '?x-oss-process=video/snapshot,t_0000,m_fast',
          style: {
            "width": "80px",
            "height": "80px",
            "object-fit": "cover"
          }
        }, null, 8, ["onClick", "src"]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", null, [_createVNode(_component_el_tag, {
          size: "small"
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("视频")])),
          _: 1
        })]), _createElementVNode("span", _hoisted_6, "上传时间:" + _toDisplayString(sp.createdTime), 1), _createVNode(_component_el_popconfirm, {
          title: "确定删除吗?",
          onConfirm: $event => $options.deleteConfirm(sp.id)
        }, {
          reference: _withCtx(() => [_createVNode(_component_el_button, {
            size: "small",
            type: "danger",
            class: "mt4",
            style: {
              "width": "60px"
            }
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("删除")])),
            _: 1
          })]),
          _: 2
        }, 1032, ["onConfirm"])])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("span", _hoisted_8, _toDisplayString(sp.text), 1)])], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_el_image, {
          src: sp.image,
          "preview-src-list": [sp.image],
          style: {
            "min-width": "80px",
            "max-width": "80px",
            "height": "80px",
            "object-fit": "cover",
            "flex": "1 0 auto"
          }
        }, null, 8, ["src", "preview-src-list"]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", null, [_createVNode(_component_el_tag, {
          type: "success",
          size: "small"
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("图片")])),
          _: 1
        })]), _createElementVNode("span", _hoisted_11, "上传时间:" + _toDisplayString(sp.createdTime), 1), _createVNode(_component_el_popconfirm, {
          title: "确定删除吗?",
          onConfirm: $event => $options.deleteConfirm(sp.id)
        }, {
          reference: _withCtx(() => [_createVNode(_component_el_button, {
            size: "small",
            type: "danger",
            class: "mt4",
            style: {
              "width": "60px"
            }
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode("删除")])),
            _: 1
          })]),
          _: 2
        }, 1032, ["onConfirm"])])]), _createElementVNode("div", _hoisted_12, [_createElementVNode("span", _hoisted_13, _toDisplayString(sp.text), 1)])], 64))]);
      }), 128)), _createVNode(_component_el_row, {
        class: "smallFont colFlex"
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: "https://cdn.waityou.online/ac818130-f5c9-437d-7a50-ff92e8d3beea.png",
          onClick: _cache[0] || (_cache[0] = $event => $data.addSpotVisible = true),
          class: "ptr",
          style: {
            "width": "80px",
            "height": "80px",
            "object-fit": "cover"
          }
        })]),
        _: 1
      }), _createVNode(_component_el_row, {
        class: "leftCenter"
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: `/api/open/qr?text=${encodeURIComponent($data.uploadPhoneUrl)}`,
          onClick: _cache[1] || (_cache[1] = $event => $data.addSpotVisible = true),
          style: {
            "width": "80px",
            "height": "80px",
            "object-fit": "cover"
          }
        }, null, 8, _hoisted_14), _cache[11] || (_cache[11] = _createElementVNode("span", {
          style: {
            "color": "lightgrey"
          },
          class: "ml8"
        }, "微信扫码使用手机上传", -1))]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]), _createVNode(_component_DialogView, {
    "data-id": "45",
    "visible-control": $data.addSpotVisible,
    onSubmit: $options.addSpot,
    onClose: _cache[2] || (_cache[2] = $event => $data.addSpotVisible = false)
  }, null, 8, ["visible-control", "onSubmit"])], 64);
}