import "core-js/modules/es.array.push.js";
import api from "@/api";
import { mapMutations } from "vuex";
export default {
  name: "FastLoginAndGoView",
  components: {},
  data() {
    return {
      fasttoken: this.$route.params.token,
      next: this.$route.query.next
    };
  },
  methods: {
    ...mapMutations(['updateUser']),
    init() {
      api.fastLogin({
        token: this.fasttoken
      }).then(ret => {
        localStorage.setItem('access_token', ret.accessToken);
        this.updateUser(ret);
        // 跳转回原页面
        this.$router.push({
          path: decodeURIComponent(this.next)
        });
      }).catch(err => {
        console.log('login err', err);
      });
    }
  },
  mounted() {
    this.init();
  }
};