import api from "@/api";
import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "MobileUserListView",
  components: {
    BreadCrumb
  },
  data() {
    return {
      switches: {},
      crumb: [{
        name: '移动端用户'
      }]
    };
  },
  methods: {
    updateEnableState(uid, val) {
      api.updateMobileUserEnable({
        userId: uid,
        val: val
      });
    },
    onFetchData(data) {
      let list = data['list'];
      for (let l of list) {
        this.switches[l['id']] = l['enable'];
      }
    }
  }
};