import AsideMenu from "@/components/AsideMenu";
import api from "@/api";
export default {
  name: "HomeView",
  components: [AsideMenu],
  data() {
    return {};
  },
  mounted() {
    api.getUserInfo(localStorage.getItem('access_token')).then(ret => {
      this.$store.commit('updateUser', ret);
      // console.log('current user',this.$store.state.user);
    });
  },
  methods: {
    logout() {
      localStorage.removeItem('access_token');
      api.logout();
    }
  }
};