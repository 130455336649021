import axios from "../utils/http";
import path from "./path"

const stringFormat = (formatted, args) => {
    for (let i = 0; i < args.length; i++) {
        let regexp = new RegExp('\\{' + i + '\\}', 'gi')
        formatted = formatted.replace(regexp, args[i])
    }
    return formatted
}
const api = {
    getOssKeySecret() {
        return axios.get(path.baseUrl + path.getOssKeySecret)
    },
    getQueryForm(formId, query) {
        return axios.get(path.baseUrl + path.queryForm[formId], {params: query})
    },
    get(url, query) {
        if (query) {
            return axios.get(path.baseUrl + url, {params: query})
        }
        return axios.get(path.baseUrl + url)
    },
    post(url, data) {
        return axios.post(path.baseUrl + url, data)
    },
    getDialogForm(dataId, bindId) {
        if (bindId != null) {
            return axios.get(path.baseUrl + stringFormat(path.dialogs[dataId], [bindId]))
        }
        return axios.get(path.baseUrl + path.dialogs[dataId])
    },
    getMenus() {
        return axios.get(path.baseUrl + path.menus)
    },
    getUserInfo(accessToken) {
        console.log('getUserInfo accessToken', accessToken);
        return axios.get(path.baseUrl + path.userInfo)
    },
    getLoginCaptchaToken() {
        return axios.get(path.baseUrl + path.loginCaptchaToken)
    },
    login(data) {
        return axios.post(path.baseUrl + path.login, data)
    },
    logout() {
        return axios.get(path.baseUrl + path.logout)
    },
    getBannerList(query) {
        return axios.get(path.baseUrl + path.getBannerList, {params: query})
    },
    createBanner(data) {
        return axios.post(path.baseUrl + path.createBanner, data);
    },
    updateBanner(data) {
        return axios.post(path.baseUrl + path.updateBanner, data);
    },
    deleteBanner(bannerId) {
        return axios.get(path.baseUrl + stringFormat(path.deleteBanner, [bannerId]))
    },

    updateSystemConfig(data) {
        return axios.post(path.baseUrl + path.updateSystemConfig, data);
    },
    getSystemConfig() {
        return axios.get(path.baseUrl + path.getSystemConfig)
    },
    updateImageValue(data) {
        return axios.post(path.baseUrl + path.updateImageValue, data);
    },
    getNextAuditPost() {
        return axios.get(path.baseUrl + path.getNextAuditPost)
    },
    auditPost(data) {
        return axios.post(path.baseUrl + path.auditPost, data);
    },
    getNextAuditComment() {
        return axios.get(path.baseUrl + path.getNextAuditComment)
    },
    auditComment(data) {
        return axios.post(path.baseUrl + path.auditComment, data);
    },
    getNextAuditUser() {
        return axios.get(path.baseUrl + path.getNextAuditUser)
    },
    auditUser(data) {
        return axios.post(path.baseUrl + path.auditUser, data);
    },
    updateQuestionRepo(data) {
        return axios.post(path.baseUrl + path.updateQuestionRepo, data);
    },
    updateQuestionRepoEnableState(repoId, enable) {
        return axios.get(path.baseUrl + stringFormat(path.updateQuestionRepoEnableState, [repoId, enable]))
    },
    deleteStudyRepo(repoId) {
        return axios.get(path.baseUrl + stringFormat(path.deleteStudyRepo, [repoId]))
    },
    createTrainTopic(data) {
        return axios.post(path.baseUrl + path.createTrainTopic, data);
    },
    createDrillTopic(data) {
        return axios.post(path.baseUrl + path.createDrillTopic, data);
    },
    updateDrillTopicEnableState(topicId) {
        return axios.get(path.baseUrl + stringFormat(path.updateDrillTopicEnableState, [topicId]))
    },
    updateTrainTopicEnableState(topicId) {
        return axios.get(path.baseUrl + stringFormat(path.updateTrainTopicEnableState, [topicId]))
    },
    updateStudyTopicEnableState(topicId) {
        return axios.get(path.baseUrl + stringFormat(path.updateStudyTopicEnableState, [topicId]))
    },
    createStudyTopic(data) {
        return axios.post(path.baseUrl + path.createStudyTopic, data);
    },
    getDepartmentList() {
        return axios.get(path.baseUrl + path.getDepartmentList)
    },
    createStation(data) {
        return axios.post(path.baseUrl + path.createStation, data);
    },
    createVisitTpl(data) {
        return axios.post(path.baseUrl + path.createVisitTpl, data);
    },
    updateVisitTpl(data) {
        return axios.post(path.baseUrl + path.updateVisitTpl, data);
    },
    updateStationVisitTpl(data) {
        return axios.post(path.baseUrl + path.updateStationVisitTpl, data);
    },
    updateMobileUserEnable(query) {
        return axios.get(path.baseUrl + path.updateMobileUserEnable, {params: query});
    },
    getVisitDate(dateId) {
        return axios.get(path.baseUrl + stringFormat(path.getVisitDate, [dateId]))
    },
    addSpotImage(data) {
        return axios.post(path.baseUrl + path.addSpotImage, data);
    },
    createQuestionRepoTopic(data) {
        return axios.post(path.baseUrl + path.createQuestionRepoTopic, data);
    },
    updateQuestionRepoTopicEnableState(topicId) {
        return axios.get(path.baseUrl + stringFormat(path.updateQuestionRepoTopicEnableState, [topicId]))
    },
    getQuestionDetail(id) {
        return axios.get(path.baseUrl + stringFormat(path.getQuestionDetail, [id]))
    },
    importQuestionFromFile(url) {
        return axios.get(path.baseUrl + stringFormat(path.importQuestionFromFile, [url]))
    },
    batchCreateQuestion(question) {
        return axios.post(path.baseUrl + path.batchCreateQuestion, question);
    },
    bindRepoId(data) {
        return axios.post(path.baseUrl + path.bindRepoId, data);
    },
    checkAndParseQuestion(data) {
        return axios.post(path.baseUrl + path.checkAndParseQuestion, data);
    },
    createQuestion(data) {
        return axios.post(path.baseUrl + path.createQuestion, data);
    },
    updateUserEnableState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateUserEnableState, [id, val]))
    },
    updateExamSetting(data) {
        return axios.post(path.baseUrl + path.updateExamSetting, data);
    },
    getRepoSetting(repoId) {
        return axios.get(path.baseUrl + stringFormat(path.getRepoSetting, [repoId]))
    },
    updateRepoSetting(data) {
        return axios.post(path.baseUrl + path.updateRepoSetting, data);
    },
    submitVisitAudit(data) {
        return axios.post(path.baseUrl + path.submitVisitAudit, data);
    },
    updateEvalutionVisible(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateEvalutionVisible, [id, val]))
    },
    createQuestionnaire(data) {
        return axios.post(path.baseUrl + path.createQuestionnaire, data);
    },
    updateQuestion(data) {
        return axios.post(path.baseUrl + path.updateQuestion, data);
    },
    updateQuestionnaireSetting(data) {
        return axios.post(path.baseUrl + path.updateQuestionnaireSetting, data);
    },
    getMapKey() {
        return axios.get(path.baseUrl + path.getMapKey)
    },
    updateStation(data) {
        return axios.post(path.baseUrl + path.updateStation, data);
    },
    setVisitState(query) {
        return axios.get(path.baseUrl + path.setVisitState, {params: query});
    },
    replyLeaveMessage(data) {
        return axios.post(path.baseUrl + path.replyLeaveMessage, data);
    },
    submitTrainAudit(data) {
        return axios.post(path.baseUrl + path.submitTrainAudit, data);
    },
    getTrainDate(dateId) {
        return axios.get(path.baseUrl + stringFormat(path.getTrainDate, [dateId]))
    },
    setTrainState(query) {
        return axios.get(path.baseUrl + path.setTrainState, {params: query});
    },
    submitDrillAudit(data) {
        return axios.post(path.baseUrl + path.submitDrillAudit, data);
    },
    getDrillDate(dateId) {
        return axios.get(path.baseUrl + stringFormat(path.getDrillDate, [dateId]))
    },
    setDrillState(query) {
        return axios.get(path.baseUrl + path.setDrillState, {params: query});
    },
    updateStationEnable(query) {
        return axios.get(path.baseUrl + path.updateStationEnable, {params: query});
    },
    getDashboardBaseData(query) {
        return axios.get(path.baseUrl + path.getDashboardBaseData, {params: query});
    },
    getDashboardStationVisitData(query) {
        return axios.get(path.baseUrl + path.getDashboardStationVisitData, {params: query});
    },
    getSquadronBySuperId(superStationId) {
        return axios.get(path.baseUrl + stringFormat(path.getSquadronBySuperId, [superStationId]))
    },
    createEnumValue(data) {
        return axios.post(path.baseUrl + path.createEnumValue, data);
    },
    updateEnumValueEnableState(dataId,value) {
        return axios.get(path.baseUrl + stringFormat(path.updateEnumValueEnableState, [dataId,value]))
    },
    auditPass(data) {
        return axios.post(path.baseUrl + path.auditPass, data);
    },
    auditReject(data) {
        return axios.post(path.baseUrl + path.auditReject, data);
    },
    updateTeachListVisible(e) {
        return axios.get(path.baseUrl + stringFormat(path.updateTeachListVisible, [e]))
    },
    getTeacherListVisible() {
        return axios.get(path.baseUrl + path.getTeacherListVisible)
    },
    deleteSpot(spotId) {
        return axios.get(path.baseUrl + stringFormat(path.deleteSpot, [spotId]))
    },
    getPolygon(stationId) {
        return axios.get(path.baseUrl + stringFormat(path.getPolygon, [stationId]))
    },
    updatePolygon(data) {
        return axios.post(path.baseUrl + path.updatePolygon, data);
    },
    updateKpiScore(id, score) {
        return axios.get(path.baseUrl + stringFormat(path.updateKpiScore, [id, score]))
    },
    getEnumValues(enumValue) {
        return axios.get(path.baseUrl + stringFormat(path.getEnumValues, [enumValue]))
    },
    getPropagandist4Select() {
        return axios.get(path.baseUrl + path.getPropagandist4Select)
    },
    addNewPost(data) {
        return axios.post(path.baseUrl + path.addNewPost, data);
    },
    updateSysConf(data) {
        return axios.post(path.baseUrl + path.updateSysConf, data);
    },
    getSysConf(query) {
        return axios.get(path.baseUrl + path.getSysConf, {params: query});
    },
    getPropagandist4Cascade() {
        return axios.get(path.baseUrl + path.getPropagandist4Cascade)
    },
    createStationVisitTpl(data) {
        return axios.post(path.baseUrl + path.createStationVisitTpl, data);
    },
    getStationVisitTemplate(query) {
        return axios.get(path.baseUrl + path.getStationVisitTemplate, {params: query});
    },
    deleteStationVisitTpl(tplId) {
        return axios.get(path.baseUrl + stringFormat(path.deleteStationVisitTpl, [tplId]))
    },
    deleteStationVisitTpls(query) {
        return axios.get(path.baseUrl + path.deleteStationVisitTpls, {params: query});
    },
    fastLogin(query) {
        return axios.get(path.baseUrl + path.fastLogin, {params: query});
    }
}

export default api;