import api from "@/api";
import BreadCrumb from "@/components/BreadCrumb";
import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "DrillListView",
  components: {
    BreadCrumb,
    BatterTableDataView
  },
  data() {
    return {
      auditVisible: false,
      auditId: null,
      crumb: [{
        name: '演练管理'
      }]
    };
  },
  methods: {
    submitAudit(data) {
      data.id = this.auditId;
      api.submitDrillAudit(data).then(() => {
        this.auditVisible = false;
        this.$refs.drillTable.fetchData();
      });
    },
    onDetailData(data) {
      window.open('/#/drill/detail/' + data.id);
    },
    audit(dateId) {
      this.auditId = dateId;
      this.auditVisible = true;
    }
  }
};