import BreadCrumb from "@/components/BreadCrumb";
import EnumValueListView from "@/components/EnumValueListView";
import api from "@/api";
import { ElMessage } from "element-plus";
import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "KpiConfigView",
  components: {
    EnumValueListView,
    BreadCrumb,
    BatterTableDataView
  },
  data() {
    return {
      activeTab: '0',
      createVisible: false,
      initQuery: {},
      switches: {},
      crumb: [{
        name: 'KPI配置管理'
      }],
      kpiAddTimeLimit: null
    };
  },
  methods: {
    formatTime(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();

      // 添加前导零
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      // 格式化为 HH:MM:SS
      return `${hours}:${minutes}:${seconds}`;
    },
    updateKpiAddTimeLimit() {
      if (!this.kpiAddTimeLimit) {
        ElMessage.error('录入时间限制不得为空!');
        return;
      }
      let data = {
        from: this.formatTime(this.kpiAddTimeLimit[0]),
        to: this.formatTime(this.kpiAddTimeLimit[1])
      };
      api.updateSysConf({
        confType: 'KPI_ADD_TIME_LIMIT',
        key: 'kpiAddTimeLimit',
        value: JSON.stringify(data)
      }).then(() => {
        ElMessage.success('更新成功!');
      });
    },
    parseTimeToTodayDate(timeString) {
      // 将时间字符串分割成小时、分钟和秒钟
      const [hours, minutes, seconds] = timeString.split(':').map(Number);

      // 创建当前日期的 Date 对象
      const date = new Date();

      // 设置小时、分钟和秒钟
      date.setHours(hours);
      date.setMinutes(minutes);
      date.setSeconds(seconds);
      return date;
    },
    init() {
      api.getSysConf({
        confType: 'KPI_ADD_TIME_LIMIT',
        key: 'kpiAddTimeLimit'
      }).then(ret => {
        const cfg = JSON.parse(ret.value);
        this.kpiAddTimeLimit = [this.parseTimeToTodayDate(cfg['from']), this.parseTimeToTodayDate(cfg['to'])];
      });
    }
  },
  mounted() {
    this.init();
  }
};