import DataPreview from "@/components/dashboard/DataPreview";
import VerticalBarChart from "@/components/chart/VerticalBarChart";
import api from "@/api";
import DashboardStations from "@/components/dashboard/DashboardStations";
import StationNumberView from "@/components/dashboard/StationNumberView";
export default {
  name: "DashboardView",
  components: {
    StationNumberView,
    DashboardStations,
    VerticalBarChart,
    DataPreview
  },
  data() {
    return {
      demoData: [],
      baseData: [],
      stations: [],
      stationWithSub: null
    };
  },
  methods: {
    onStationClick(s) {
      console.log('onStationClick', s);
      if (s.type != 'BATTALION') {
        return;
      }
      api.getSquadronBySuperId(s.id).then(ret => {
        this.stationWithSub = ret;
      });
    }
  },
  mounted() {
    api.getDashboardBaseData().then(list => {
      this.baseData = list;
    });
    api.getDashboardStationVisitData().then(stations => {
      this.stations = stations;
      this.onStationClick(stations[0]);
    });
  }
};