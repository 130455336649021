import BatterTableDataView from "@/components/BatterTableDataView";
import BreadCrumb from "@/components/BreadCrumb";
import DialogView from "@/components/DialogView";
import api from "@/api";
import { ElMessage } from "element-plus";
import DialogCustomView from "@/components/DialogCustomView";
import KpiAddDialog from "@/components/kpi/KpiAddDialog";
export default {
  name: "KpiAssessmentListView",
  components: {
    KpiAddDialog,
    DialogView,
    BatterTableDataView,
    BreadCrumb,
    DialogCustomView
  },
  data() {
    return {
      addVisible: false,
      passVisible: false,
      rejectVisible: false,
      initQuery: {},
      switches: {},
      score: {},
      auditId: null,
      crumb: [{
        name: 'KPI考核'
      }]
    };
  },
  methods: {
    addNewPost(data) {
      api.addNewPost(data).then(() => {
        this.addVisible = false;
        this.$refs.kpiTable.fetchData();
        this.$refs.kpiAddDialog.reset();
      });
    },
    updateScore(id) {
      api.updateKpiScore(id, this.score[id]);
    },
    onFetchData(d) {
      for (let itm of d.list) {
        this.score[itm.id] = itm.score;
      }
    },
    onImport() {
      ElMessage.info('敬请期待');
    },
    auditPass(data) {
      data.id = this.auditId;
      api.auditPass(data).then(() => {
        this.passVisible = false;
        this.$refs.kpiTable.fetchData();
      });
    },
    auditReject(data) {
      data.id = this.auditId;
      api.auditReject(data).then(() => {
        this.rejectVisible = false;
        this.$refs.kpiTable.fetchData();
      });
    },
    pass(id) {
      this.auditId = id;
      this.passVisible = true;
    },
    reject(id) {
      this.auditId = id;
      this.rejectVisible = true;
    }
  }
};