import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  style: {
    "background-color": "white"
  }
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  key: 0
};
const _hoisted_7 = {
  key: 1
};
const _hoisted_8 = {
  key: 0
};
const _hoisted_9 = ["onClick"];
const _hoisted_10 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_el_row = _resolveComponent("el-row");
  const _component_svg_icon = _resolveComponent("svg-icon");
  const _component_el_tree = _resolveComponent("el-tree");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_QuestionFilled = _resolveComponent("QuestionFilled");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_popover = _resolveComponent("el-popover");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_LocationPicker = _resolveComponent("LocationPicker");
  const _component_DialogView = _resolveComponent("DialogView");
  const _component_SetPolygonView = _resolveComponent("SetPolygonView");
  const _component_DialogCustomView = _resolveComponent("DialogCustomView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_cache[7] || (_cache[7] = _createElementVNode("span", {
      class: "pageTitle padding20"
    }, "站点管理", -1)), _createVNode(_component_el_divider, {
      class: "padding0 margin0"
    })]),
    _: 1
  }), _createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 4,
      class: "leftCol"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_row, {
        class: "help"
      }, {
        default: _withCtx(() => [_createVNode(_component_svg_icon, {
          "icon-class": "press",
          size: "16px"
        }), _cache[8] || (_cache[8] = _createElementVNode("span", null, "长按拖拽，修改排序", -1))]),
        _: 1
      }), _createVNode(_component_el_tree, {
        "allow-drop": $options.allowDropCheck,
        data: $data.departments,
        draggable: "",
        "default-expand-all": "",
        "expand-on-click-node": false,
        "node-key": "id",
        props: "treeProps",
        class: "stationList",
        onNodeClick: $options.onNodeClick
      }, null, 8, ["allow-drop", "data", "onNodeClick"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 20,
      class: "rightCol"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_row, {
        style: {
          "padding": "20px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 12
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            style: {
              "background-color": "#005FF5",
              "color": "white"
            },
            onClick: _cache[0] || (_cache[0] = $event => $data.stationCreateVisible = true)
          }, {
            default: _withCtx(() => [_createVNode(_component_svg_icon, {
              "icon-class": "add"
            }), _cache[9] || (_cache[9] = _createTextVNode("  添加 "))]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 12,
          class: "rightCenter"
        }, {
          default: _withCtx(() => [$data.myStationId <= 1 ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 0
          }, [_createVNode(_component_el_switch, {
            modelValue: $data.visitOpen,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.visitOpen = $event),
            onChange: $options.changeVisitOpen
          }, null, 8, ["modelValue", "onChange"]), _cache[10] || (_cache[10] = _createElementVNode("span", {
            class: "ml12"
          }, "全站预约开关", -1)), _createVNode(_component_el_popover, {
            placement: "top-start",
            title: "全站预约开关",
            width: 200,
            trigger: "hover",
            content: "关闭后则全站均禁止预约；开启后各站自行控制；"
          }, {
            reference: _withCtx(() => [_createVNode(_component_el_icon, {
              class: "mr32"
            }, {
              default: _withCtx(() => [_createVNode(_component_QuestionFilled)]),
              _: 1
            })]),
            _: 1
          })], 64)) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_row, {
        style: {
          "width": "100%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_BatterTableDataView, {
          "table-id": "1",
          "init-query": $data.initQuery,
          onOnFetchData: $options.onFetchData,
          onOnEditData: $options.onEditData,
          ref: "stationTable"
        }, {
          "operates-handle": _withCtx(data => [_createElementVNode("span", {
            onClick: $event => $options.setPolygon(data.rowId),
            style: {
              "cursor": "pointer",
              "color": "#063677"
            }
          }, "设置", 8, _hoisted_2)]),
          icon: _withCtx(data => [_createElementVNode("img", {
            src: data.rowData,
            style: {
              "width": "60px",
              "height": "60px",
              "object-fit": "cover"
            }
          }, null, 8, _hoisted_3)]),
          type: _withCtx(data => [_createTextVNode(_toDisplayString(data.mapping[data.rowData]), 1)]),
          centralStation: _withCtx(data => [data.data.type == 'DETACHMENT' || data.data.type == 'BATTALION' ? (_openBlock(), _createElementBlock("span", _hoisted_4, "-")) : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(data.rowData == 'YES' ? '是' : '否'), 1))]),
          openState: _withCtx(data => [data.data.type == 'DETACHMENT' || data.data.type == 'BATTALION' ? (_openBlock(), _createElementBlock("span", _hoisted_6, "-")) : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(data.rowData == 'YES' ? '是' : '否'), 1))]),
          stationTemplateName: _withCtx(data => [data.data.type == 'DETACHMENT' || data.data.type == 'BATTALION' ? (_openBlock(), _createElementBlock("span", _hoisted_8, "-")) : (_openBlock(), _createElementBlock("span", {
            key: 1,
            style: {
              "color": "#1388ff",
              "cursor": "pointer"
            },
            onClick: $event => $options.setVisitTpl(data.data)
          }, _cache[11] || (_cache[11] = [_createElementVNode("span", {
            style: {
              "color": "#1388ff",
              "cursor": "pointer"
            }
          }, "前往设置", -1)]), 8, _hoisted_9))]),
          enable: _withCtx(data => [data.data.type == 'DETACHMENT' || data.data.type == 'BATTALION' ? (_openBlock(), _createElementBlock("span", _hoisted_10, "-")) : (_openBlock(), _createBlock(_component_el_switch, {
            key: 1,
            modelValue: $data.switches[data.rowId],
            "onUpdate:modelValue": $event => $data.switches[data.rowId] = $event,
            onChange: val => $options.updateEnableState(data.rowId, val),
            class: "ml-2",
            style: {
              "--el-switch-on-color": "#005FF5",
              "--el-switch-off-color": "lightgray"
            }
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]))]),
          _: 1
        }, 8, ["init-query", "onOnFetchData", "onOnEditData"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]), _createVNode(_component_DialogView, {
    "data-id": 19,
    onClose: _cache[2] || (_cache[2] = $event => $data.stationCreateVisible = false),
    onSubmit: $options.createStation,
    "form-override-data": $data.formOverWrite,
    "visible-control": $data.stationCreateVisible
  }, {
    custom: _withCtx(() => [_cache[12] || (_cache[12] = _createElementVNode("span", null, "站点定位：", -1)), (_openBlock(), _createBlock(_component_LocationPicker, {
      longitude: $data.createLongitude,
      latitude: $data.createLatitude,
      zoom: 16,
      height: "300px",
      key: $data.createDataVersion,
      onOnPickerPosition: $options.onPickerPosition
    }, null, 8, ["longitude", "latitude", "onOnPickerPosition"]))]),
    _: 1
  }, 8, ["onSubmit", "form-override-data", "visible-control"]), _createVNode(_component_DialogView, {
    "data-id": 1,
    "bind-id": $data.editId,
    onClose: _cache[3] || (_cache[3] = $event => $data.stationEditVisible = false),
    onSubmit: $options.updateStation,
    "form-override-data": $data.formOverWrite,
    "visible-control": $data.stationEditVisible
  }, {
    custom: _withCtx(() => [_cache[13] || (_cache[13] = _createElementVNode("span", null, "站点定位：", -1)), $data.stationEditVisible ? (_openBlock(), _createBlock(_component_LocationPicker, {
      longitude: $data.editLongitude,
      latitude: $data.editLatitude,
      zoom: 16,
      height: "300px",
      key: $data.editDataVersion,
      onOnPickerPosition: $options.onPickerPosition
    }, null, 8, ["longitude", "latitude", "onOnPickerPosition"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["bind-id", "onSubmit", "form-override-data", "visible-control"]), _createVNode(_component_DialogView, {
    "data-id": 18,
    onClose: _cache[4] || (_cache[4] = $event => $data.stationTplVisible = false),
    "bind-id": $data.editTplStationId,
    "visible-control": $data.stationTplVisible,
    onSubmit: $options.updateStationTpl
  }, null, 8, ["bind-id", "visible-control", "onSubmit"]), _createVNode(_component_DialogCustomView, {
    title: "服务区域设置",
    width: "50%",
    "visible-control": $data.polygonVisible,
    onClose: _cache[6] || (_cache[6] = $event => $data.polygonVisible = false)
  }, {
    default: _withCtx(() => [$data.polygonVisible ? (_openBlock(), _createBlock(_component_SetPolygonView, {
      key: 0,
      "station-id": $data.setPolygonStationId,
      onClose: _cache[5] || (_cache[5] = $event => $data.polygonVisible = false)
    }, null, 8, ["station-id"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["visible-control"])], 64);
}