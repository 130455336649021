import DialogCustomView from "@/components/DialogCustomView";
import VisitTplEditor from "@/components/station/VisitTplEditor";
import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "VisitTplView",
  components: {
    VisitTplEditor,
    DialogCustomView,
    BatterTableDataView
  },
  methods: {
    onEditData(d) {
      this.editObj = d;
      this.createVisible = true;
    },
    onClose() {
      this.createVisible = false;
      this.$refs.visitTplTable.fetchData();
      this.editObj = {};
    }
  },
  data() {
    return {
      editObj: {},
      createVisible: false
    };
  }
};