import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_radio = _resolveComponent("el-radio");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_col = _resolveComponent("el-col");
  const _component_svg_icon = _resolveComponent("svg-icon");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_checkbox = _resolveComponent("el-checkbox");
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
  return _openBlock(), _createBlock(_component_el_row, {
    class: "formContainer"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_row, null, {
      default: _withCtx(() => _cache[9] || (_cache[9] = [_createElementVNode("span", {
        class: "required"
      }, "*", -1), _createElementVNode("span", null, "试题类型", -1)])),
      _: 1
    }), _createVNode(_component_el_row, {
      style: {
        "padding": "10px"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_radio_group, {
        modelValue: $data.questionType,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.questionType = $event)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_radio, {
          label: "0",
          size: "large"
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode("单选")])),
          _: 1
        }), _createVNode(_component_el_radio, {
          label: "1",
          size: "large"
        }, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [_createTextVNode("多选")])),
          _: 1
        }), _createVNode(_component_el_radio, {
          label: "2",
          size: "large"
        }, {
          default: _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode("判断")])),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_row, null, {
      default: _withCtx(() => _cache[13] || (_cache[13] = [_createElementVNode("span", {
        class: "required"
      }, "*", -1), _createElementVNode("span", null, "试题题目", -1)])),
      _: 1
    }), _createVNode(_component_el_row, {
      style: {
        "padding": "10px"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        type: "textarea",
        modelValue: $data.questionContent,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.questionContent = $event),
        placeholder: "请输入试题题目"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _withDirectives(_createVNode(_component_el_row, null, {
      default: _withCtx(() => _cache[14] || (_cache[14] = [_createElementVNode("span", {
        class: "required"
      }, "*", -1), _createElementVNode("span", null, "试题选项", -1)])),
      _: 1
    }, 512), [[_vShow, $data.questionType != 2]]), _withDirectives(_createVNode(_component_el_row, {
      style: {
        "padding": "10px",
        "display": "flex",
        "flex-direction": "column"
      }
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.questionOption, (op, idx) => {
        return _openBlock(), _createBlock(_component_el_row, {
          style: {
            "display": "flex",
            "align-items": "center",
            "padding": "10px"
          },
          key: idx
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 4
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString($data.options[idx]) + "、", 1)]),
            _: 2
          }, 1024), _createVNode(_component_el_col, {
            span: 16
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: $data.questionOption[idx],
              "onUpdate:modelValue": $event => $data.questionOption[idx] = $event,
              placeholder: "请输入试题选项"
            }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
            _: 2
          }, 1024), _createVNode(_component_el_col, {
            span: 4,
            style: {
              "display": "flex",
              "justify-content": "center"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_svg_icon, {
              "icon-class": "trash",
              size: "20px",
              onClick: $event => $data.questionOption.splice(idx, 1)
            }, null, 8, ["onClick"])]),
            _: 2
          }, 1024)]),
          _: 2
        }, 1024);
      }), 128)), $data.questionOption.length < $data.options.length ? _withDirectives((_openBlock(), _createBlock(_component_el_row, {
        key: 0
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          onClick: _cache[2] || (_cache[2] = $event => $data.questionOption.push('')),
          style: {
            "width": "200px",
            "background-color": "transparent",
            "color": "#005FF5",
            "border-color": "#005FF5"
          },
          size: "large"
        }, {
          default: _withCtx(() => _cache[15] || (_cache[15] = [_createTextVNode(" 添加选项 ")])),
          _: 1
        })]),
        _: 1
      }, 512)), [[_vShow, $data.questionType != 2]]) : _createCommentVNode("", true)]),
      _: 1
    }, 512), [[_vShow, $data.questionType != 2]]), _createVNode(_component_el_row, null, {
      default: _withCtx(() => _cache[16] || (_cache[16] = [_createElementVNode("span", {
        class: "required"
      }, "*", -1), _createElementVNode("span", null, "答案", -1)])),
      _: 1
    }), $data.questionType == 0 ? (_openBlock(), _createBlock(_component_el_row, {
      key: 0,
      style: {
        "padding": "10px"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_radio_group, {
        modelValue: $data.answer,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.answer = $event)
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.questionOption, (ans, idx) => {
          return _openBlock(), _createBlock(_component_el_radio, {
            key: idx,
            label: $data.options[idx],
            size: "large"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString($data.options[idx]), 1)]),
            _: 2
          }, 1032, ["label"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    })) : _createCommentVNode("", true), $data.questionType == 1 ? (_openBlock(), _createBlock(_component_el_row, {
      key: 1,
      style: {
        "padding": "10px"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_checkbox_group, {
        modelValue: $data.answers,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.answers = $event)
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.questionOption, (ans, idx) => {
          return _openBlock(), _createBlock(_component_el_checkbox, {
            key: idx,
            label: $data.options[idx],
            size: "large"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString($data.options[idx]), 1)]),
            _: 2
          }, 1032, ["label"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    })) : _createCommentVNode("", true), $data.questionType == 2 ? (_openBlock(), _createBlock(_component_el_row, {
      key: 2,
      style: {
        "padding": "10px"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_radio_group, {
        modelValue: $data.answer,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $data.answer = $event)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_radio, {
          label: "A",
          size: "large"
        }, {
          default: _withCtx(() => _cache[17] || (_cache[17] = [_createTextVNode("正确")])),
          _: 1
        }), _createVNode(_component_el_radio, {
          label: "B",
          size: "large"
        }, {
          default: _withCtx(() => _cache[18] || (_cache[18] = [_createTextVNode("错误")])),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    })) : _createCommentVNode("", true), _createVNode(_component_el_row, null, {
      default: _withCtx(() => _cache[19] || (_cache[19] = [_createElementVNode("span", null, "解析", -1)])),
      _: 1
    }), _createVNode(_component_el_row, {
      style: {
        "padding": "10px"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        type: "textarea",
        modelValue: $data.questionAnalysis,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => $data.questionAnalysis = $event),
        placeholder: "请输入试题解析"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_row, null, {
      default: _withCtx(() => _cache[20] || (_cache[20] = [_createElementVNode("span", null, "难易程度", -1)])),
      _: 1
    }), _createVNode(_component_el_row, {
      style: {
        "padding": "10px"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_radio_group, {
        modelValue: $data.hardType,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => $data.hardType = $event)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_radio, {
          label: "0",
          size: "large"
        }, {
          default: _withCtx(() => _cache[21] || (_cache[21] = [_createTextVNode("易")])),
          _: 1
        }), _createVNode(_component_el_radio, {
          label: "1",
          size: "large"
        }, {
          default: _withCtx(() => _cache[22] || (_cache[22] = [_createTextVNode("偏易")])),
          _: 1
        }), _createVNode(_component_el_radio, {
          label: "2",
          size: "large"
        }, {
          default: _withCtx(() => _cache[23] || (_cache[23] = [_createTextVNode("适中")])),
          _: 1
        }), _createVNode(_component_el_radio, {
          label: "3",
          size: "large"
        }, {
          default: _withCtx(() => _cache[24] || (_cache[24] = [_createTextVNode("偏难")])),
          _: 1
        }), _createVNode(_component_el_radio, {
          label: "4",
          size: "large"
        }, {
          default: _withCtx(() => _cache[25] || (_cache[25] = [_createTextVNode("难")])),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_row, null, {
      default: _withCtx(() => _cache[26] || (_cache[26] = [_createElementVNode("span", null, "试题来源", -1)])),
      _: 1
    }), _createVNode(_component_el_row, {
      style: {
        "padding": "10px"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.source,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => $data.source = $event),
        placeholder: "请输入试题来源"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_row, {
      style: {
        "justify-content": "center"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        style: {
          "width": "200px"
        },
        type: "primary",
        size: "large",
        onClick: $options.submit
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($data.btnName), 1)]),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  });
}