import BreadCrumb from "@/components/BreadCrumb";
import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "VisitStatisticView",
  components: {
    BreadCrumb,
    BatterTableDataView
  },
  data() {
    return {
      crumb: [{
        name: '参观统计'
      }]
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};