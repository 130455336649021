import BatterTableDataView from "@/components/BatterTableDataView";
import DialogView from "@/components/DialogView";
import api from "@/api";
import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "VisitListView",
  components: {
    BreadCrumb,
    DialogView,
    BatterTableDataView
  },
  data() {
    return {
      auditVisible: false,
      auditId: null,
      crumb: [{
        name: '参观管理'
      }]
    };
  },
  methods: {
    submitAudit(data) {
      data.id = this.auditId;
      api.submitVisitAudit(data).then(() => {
        this.auditVisible = false;
        this.$refs.dateTable.fetchData();
      });
    },
    onDetailData(data) {
      window.open('/#/visit/detail/' + data.id);
    },
    audit(dateId) {
      this.auditId = dateId;
      this.auditVisible = true;
    }
  }
};