import "core-js/modules/es.array.push.js";
import SvgIcon from "@/components/SvgIcon";
import BatterTableDataView from "@/components/BatterTableDataView";
import DialogView from "@/components/DialogView";
import api from "@/api";
import LocationPicker from "@/components/dashboard/LocationPicker";
import DialogCustomView from "@/components/DialogCustomView";
import SetPolygonView from "@/components/station/SetPolygonView";
import { ElMessage } from "element-plus";
export default {
  name: "StationManageView",
  components: {
    SetPolygonView,
    DialogCustomView,
    LocationPicker,
    DialogView,
    BatterTableDataView,
    SvgIcon
  },
  data() {
    return {
      switches: {},
      createLatitude: 30.246552,
      createLongitude: 120.209882,
      createDataVersion: 0,
      editId: null,
      editLatitude: null,
      editLongitude: null,
      editDataVersion: 0,
      formOverWrite: {},
      stationTplVisible: false,
      polygonVisible: false,
      editTplStationId: 0,
      setPolygonStationId: 0,
      stationCreateVisible: false,
      stationEditVisible: false,
      departments: [{
        label: '杭州市消防支队',
        children: []
      }],
      treeProps: {
        class: 'stationList'
      },
      initQuery: {},
      myStationId: 999,
      visitOpen: true
    };
  },
  methods: {
    setPolygon(stationId) {
      this.setPolygonStationId = stationId;
      this.polygonVisible = true;
    },
    updateEnableState(uid, val) {
      api.updateStationEnable({
        id: uid,
        val: val
      });
    },
    onFetchData(data) {
      let list = data['list'];
      for (let l of list) {
        this.switches[l['id']] = l['enable'];
      }
      this.init();
    },
    updateStation(data) {
      data.id = this.editId;
      if (!data.longitude) {
        data.longitude = this.editLongitude;
      }
      if (!data.latitude) {
        data.latitude = this.editLatitude;
      }
      api.updateStation(data).then(() => {
        this.stationEditVisible = false;
        this.formOverWrite = {};
        this.$refs.stationTable.fetchData();
      });
    },
    onEditData(data) {
      this.editId = data.id;
      this.editLongitude = data.longitude;
      this.editLatitude = data.latitude;
      this.stationEditVisible = true;
    },
    onPickerPosition(pos) {
      this.formOverWrite = {
        'longitude': pos[0],
        'latitude': pos[1]
      };
    },
    updateStationTpl() {
      // data.stationId = this.editTplStationId;
      // api.updateStationVisitTpl({
      //   stationId: this.editTplStationId,
      //   setTemplateId: data.templateId,
      // }).then(() => {
      //   this.stationTplVisible = false;
      //   this.$refs.stationTable.fetchData()
      // })
    },
    setVisitTpl(data) {
      // this.editTplStationId = data.id;
      // this.stationTplVisible = true;
      this.$router.push(`/station/visit/tpl/setting/${data.id}`);
    },
    createStation(data) {
      api.createStation(data).then(() => {
        this.stationCreateVisible = false;
        this.formOverWrite = {};
        this.$refs.stationTable.fetchData();
      });
    },
    onNodeClick(e) {
      this.initQuery = {
        superStationDepartmentId: e.value ? e.value : 1
      };
    },
    allowDropCheck(draggingNode, dropNode, type) {
      // 禁止叶子节点成为其他节点的子节点
      if (type === 'inner') {
        return false;
      }
      // 禁止拖动节点被拖出其父节点
      if (dropNode.parent != draggingNode.parent) {
        return false;
      }
      // 禁止节点被拖到其子孙节点下
      if (dropNode.descendants && dropNode.descendants.indexOf(draggingNode) !== -1) {
        return false;
      }
      // 其他情况允许放置
      return true;
    },
    changeVisitOpen() {
      api.updateSysConf({
        confType: 'SYSTEM_SETTING',
        key: 'CLOSE_VISIT',
        value: this.visitOpen ? 'OPEN' : 'CLOSE'
      }).then(() => {
        ElMessage.success('更新成功!');
      });
    },
    init() {
      api.getDepartmentList().then(ret => {
        this.departments = [{
          label: '杭州市消防支队',
          children: ret
        }];
      });
      api.getUserInfo().then(ret => {
        this.myStationId = ret.stationId;
      });
      api.getSysConf({
        confType: 'SYSTEM_SETTING',
        key: 'CLOSE_VISIT'
      }).then(ret => {
        this.visitOpen = (ret === null || ret === void 0 ? void 0 : ret.value) != 'CLOSE';
      });
    }
  },
  mounted() {
    this.init();
  }
};