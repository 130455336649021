import QuestionImportPageView from "@/components/question/QuestionImportPageView";
import api from "@/api";
export default {
  name: "RepoImportView",
  components: {
    QuestionImportPageView
  },
  data() {
    return {
      importType: '批量导入',
      repoId: this.$route.params.repoId
    };
  },
  methods: {
    submitQids(qids) {
      api.bindRepoId({
        'repoId': this.repoId,
        'qids': qids
      }).then(() => {
        this.$router.back();
      });
    }
  }
};