import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "qIndex"
};
const _hoisted_2 = {
  class: "qType"
};
const _hoisted_3 = {
  class: "qContent"
};
const _hoisted_4 = {
  style: {
    "background-color": "#ffe5e5",
    "color": "red"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_Delete = _resolveComponent("Delete");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_radio = _resolveComponent("el-radio");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  const _component_el_checkbox = _resolveComponent("el-checkbox");
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass($props.question.error ? 'QuestionListItemContainer error' : 'QuestionListItemContainer')
  }, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 12,
      class: "leftCenter"
    }, {
      default: _withCtx(() => [_createElementVNode("span", _hoisted_1, _toDisplayString($props.qIndex + 1) + ".", 1), _createElementVNode("span", _hoisted_2, _toDisplayString($data.qTypes[$props.question.type]), 1)]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 12,
      class: "rightCenter"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_icon, {
        style: {
          "cursor": "pointer",
          "margin-right": "10px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_Delete, {
          onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('delete', $props.qIndex))
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_3, _toDisplayString($props.question.description), 1)]),
    _: 1
  }), $props.question.type != 'MULTIPLE' ? (_openBlock(), _createBlock(_component_el_row, {
    key: 0,
    class: "qOption"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_radio_group, {
      modelValue: $data.rightAnswers,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.rightAnswers = $event),
      onChange: $options.answerChanged,
      style: {
        "flex-direction": "column",
        "align-items": "flex-start"
      }
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.question.options, (op, i) => {
        return _openBlock(), _createBlock(_component_el_radio, {
          label: $data.options[i],
          key: i,
          style: {
            "margin-right": "0"
          },
          size: "large"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($data.options[i]) + "、" + _toDisplayString(op), 1)]),
          _: 2
        }, 1032, ["label"]);
      }), 128))]),
      _: 1
    }, 8, ["modelValue", "onChange"])]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_el_row, {
    key: 1,
    class: "qOption"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_checkbox_group, {
      modelValue: $data.rightAnswers,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.rightAnswers = $event),
      onChange: $options.answerChanged,
      style: {
        "display": "flex",
        "flex-direction": "column",
        "align-items": "flex-start"
      }
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.question.options, (op, i) => {
        return _openBlock(), _createBlock(_component_el_checkbox, {
          key: i,
          label: $data.options[i]
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($data.options[i]) + "、" + _toDisplayString(op), 1)]),
          _: 2
        }, 1032, ["label"]);
      }), 128))]),
      _: 1
    }, 8, ["modelValue", "onChange"])]),
    _: 1
  })), _createVNode(_component_el_row, null, {
    default: _withCtx(() => [_cache[3] || (_cache[3] = _createElementVNode("span", {
      style: {
        "color": "#999999"
      }
    }, "解析：", -1)), _createElementVNode("span", null, _toDisplayString($props.question.analysis), 1)]),
    _: 1
  }), $props.question.error ? (_openBlock(), _createBlock(_component_el_row, {
    key: 2
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_4, _toDisplayString($props.question.error), 1)]),
    _: 1
  })) : _createCommentVNode("", true)], 2);
}