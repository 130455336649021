import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "VisitDetailViewContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_col = _resolveComponent("el-col");
  const _component_VisitComment = _resolveComponent("VisitComment");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_popconfirm = _resolveComponent("el-popconfirm");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "pd16"
  }, {
    default: _withCtx(() => [_createVNode(_component_BreadCrumb, {
      "no-divider": "",
      data: $data.crumb
    }, null, 8, ["data"])]),
    _: 1
  }), _cache[12] || (_cache[12] = _createElementVNode("div", {
    class: "divider"
  }, null, -1)), _createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 16,
      class: "colFlex pd20"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_row, {
        class: "bothCenter mb16"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 4,
          class: "leftCenter"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode(" 申请单位 ")])),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 8,
          class: "leftCenter"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($data.train.applyUnitName), 1)]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 4,
          class: "leftCenter"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode(" 申请提交时间 ")])),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 8,
          class: "leftCenter"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($data.train.createdTime), 1)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_row, {
        class: "bothCenter mb16"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 4,
          class: "leftCenter"
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode(" 单位地址 ")])),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 8,
          class: "leftCenter"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($data.train.address), 1)]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 4,
          class: "leftCenter"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode(" 预约时间 ")])),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 8,
          class: "leftCenter"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($data.train.expectStartTime) + " ~ " + _toDisplayString($data.train.expectEndTime), 1)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_row, {
        class: "bothCenter mb16"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 4,
          class: "leftCenter"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode(" 培训人数 ")])),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 8,
          class: "leftCenter"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($data.train.personNum), 1)]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 4,
          class: "leftCenter"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode(" 确定时间 ")])),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 8,
          class: "leftCenter"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($data.train.confirmStartTime), 1)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_row, {
        class: "bothCenter mb16"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 4,
          class: "leftCenter"
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode(" 联系人 ")])),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 8,
          class: "leftCenter"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($data.train.teamLeader), 1)]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 4,
          class: "leftCenter"
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode(" 联系方式 ")])),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 8,
          class: "leftCenter"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($data.train.teamLeaderPhone), 1)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_row, {
        class: "leftCenter mb16"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 4,
          class: "leftCenter"
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode(" 讲师 ")])),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 8,
          class: "leftCenter"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($data.train.teacherName), 1)]),
          _: 1
        })]),
        _: 1
      }), _cache[9] || (_cache[9] = _createElementVNode("span", {
        class: "baseFont mb16"
      }, "评价内容:", -1)), _createVNode(_component_el_row, {
        gutter: 10
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.comments, cmm => {
          return _openBlock(), _createBlock(_component_el_col, {
            span: 8,
            class: "bothCenter",
            key: cmm.id
          }, {
            default: _withCtx(() => [_createVNode(_component_VisitComment, {
              data: cmm,
              onEnableComment: $event => $options.enableComment(cmm),
              onDisEnableComment: $event => $options.disEnableComment(cmm)
            }, null, 8, ["data", "onEnableComment", "onDisEnableComment"])]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 8,
      class: "colFlex pd20"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_row, {
        class: "bothCenter mb16"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_popconfirm, {
          title: "现在就标记培训开始吗？系统会在开始培训时间自动变更状态",
          onConfirm: $options.startTrain
        }, {
          reference: _withCtx(() => {
            var _$data$train;
            return [((_$data$train = $data.train) === null || _$data$train === void 0 ? void 0 : _$data$train.state) == 'PASS' ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              class: "mr8"
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode("培训开始")])),
              _: 1
            })) : _createCommentVNode("", true)];
          }),
          _: 1
        }, 8, ["onConfirm"]), _createVNode(_component_el_popconfirm, {
          title: "现在就标记培训完成吗？系统会在培训结束时间自动变更状态",
          onConfirm: $options.finishTrain
        }, {
          reference: _withCtx(() => {
            var _$data$train2;
            return [((_$data$train2 = $data.train) === null || _$data$train2 === void 0 ? void 0 : _$data$train2.state) == 'PROCESSING' ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              class: "mr8"
            }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [_createTextVNode("培训完成")])),
              _: 1
            })) : _createCommentVNode("", true)];
          }),
          _: 1
        }, 8, ["onConfirm"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}