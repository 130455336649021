import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "BreadCrumbContainer smallFont"
};
const _hoisted_2 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  const _component_el_divider = _resolveComponent("el-divider");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, (b, bi) => {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: bi
    }, [!b.link ? (_openBlock(), _createElementBlock("span", {
      key: 0,
      style: _normalizeStyle({
        color: bi == $props.data.length - 1 ? '#000' : '#999999'
      })
    }, _toDisplayString(b.name), 5)) : (_openBlock(), _createBlock(_component_router_link, {
      key: 1,
      to: b.link,
      style: {
        "text-decoration": "none",
        "color": "#999999"
      }
    }, {
      default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(b.name), 1)]),
      _: 2
    }, 1032, ["to"])), bi != $props.data.length - 1 ? (_openBlock(), _createElementBlock("span", _hoisted_2, " / ")) : _createCommentVNode("", true)], 64);
  }), 128))]), !$props.noDivider ? (_openBlock(), _createBlock(_component_el_divider, {
    key: 0,
    style: {
      "margin": "16px 0"
    }
  })) : _createCommentVNode("", true)], 64);
}