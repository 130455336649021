import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "wbg pd16"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  key: 0,
  style: {
    "width": "100%"
  }
};
const _hoisted_5 = {
  style: {
    "color": "red"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_router_link = _resolveComponent("router-link");
  const _component_el_popconfirm = _resolveComponent("el-popconfirm");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_el_row = _resolveComponent("el-row");
  const _component_DialogView = _resolveComponent("DialogView");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_button = _resolveComponent("el-button");
  const _component_DialogCustomView = _resolveComponent("DialogCustomView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createVNode(_component_el_row, {
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_BatterTableDataView, {
      "table-id": "8",
      ref: "repoTable",
      onOnFetchData: $options.onFetchData
    }, {
      category: _withCtx(data => [_createTextVNode(_toDisplayString(data.mapping[data.rowData]), 1)]),
      enable: _withCtx(data => [_createVNode(_component_el_switch, {
        "model-value": $data.switches[data.rowId],
        onChange: val => $options.updateEnableState(data.rowId, val),
        class: "ml-2",
        style: {
          "--el-switch-on-color": "#005FF5",
          "--el-switch-off-color": "lightgray"
        }
      }, null, 8, ["model-value", "onChange"])]),
      operate: _withCtx(data => [_createVNode(_component_router_link, {
        to: '/study/import/' + data.rowId,
        style: {
          "text-decoration": "none"
        }
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [_createElementVNode("span", {
          class: "blueBtn"
        }, "添加试题", -1)])),
        _: 2
      }, 1032, ["to"]), _createVNode(_component_router_link, {
        to: '/question/manage/' + data.rowId,
        style: {
          "text-decoration": "none"
        }
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [_createElementVNode("span", {
          class: "blueBtn"
        }, "试题管理", -1)])),
        _: 2
      }, 1032, ["to"]), _createElementVNode("span", {
        class: "blueBtn",
        onClick: $event => $options.examSetting(data.rowId)
      }, "测试设置", 8, _hoisted_2), _createElementVNode("span", {
        class: "blueBtn",
        onClick: $event => {
          $data.editBindId = data.rowId;
          $data.editDialogVisible = true;
        }
      }, "编辑", 8, _hoisted_3), _createVNode(_component_el_popconfirm, {
        title: "确定删除吗",
        onConfirm: $event => $options.deleteRepo(data.rowId)
      }, {
        reference: _withCtx(() => _cache[8] || (_cache[8] = [_createElementVNode("span", {
          class: "redBtn"
        }, "删除", -1)])),
        _: 2
      }, 1032, ["onConfirm"])]),
      _: 1
    }, 8, ["onOnFetchData"])]),
    _: 1
  })]), _createVNode(_component_DialogView, {
    "visible-control": $data.editDialogVisible,
    "data-id": 5,
    "bind-id": $data.editBindId,
    onSubmit: $options.updateData,
    onOnDialogClose: _cache[0] || (_cache[0] = $event => {
      $data.editDialogVisible = false;
    })
  }, null, 8, ["visible-control", "bind-id", "onSubmit"]), _createVNode(_component_DialogCustomView, {
    title: "测试设置",
    onClose: _cache[5] || (_cache[5] = $event => $data.configVisible = false),
    "visible-control": $data.configVisible
  }, {
    default: _withCtx(() => [$data.configVisible ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_el_row, {
      class: "bothCenter"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 12,
        class: "leftCenter"
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [_createElementVNode("span", {
          style: {
            "color": "red"
          }
        }, "*", -1), _createElementVNode("span", null, "合格分数", -1)])),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 12,
        class: "leftCenter"
      }, {
        default: _withCtx(() => [_withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.setting.passScore = $event),
          class: "settingInput"
        }, null, 512), [[_vModelText, $data.setting.passScore]])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, {
      class: "bothCenter mt16"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 12,
        class: "leftCenter"
      }, {
        default: _withCtx(() => [_cache[10] || (_cache[10] = _createElementVNode("span", {
          style: {
            "color": "red"
          }
        }, "*", -1)), _createElementVNode("span", null, "单选题（" + _toDisplayString($data.setting.questionDetails[0].count) + "道），每题", 1)]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 12,
        class: "leftCenter"
      }, {
        default: _withCtx(() => [_withDirectives(_createElementVNode("input", {
          class: "settingInput",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.setting.questionDetails[0].score = $event)
        }, null, 512), [[_vModelText, $data.setting.questionDetails[0].score]]), _cache[11] || (_cache[11] = _createTextVNode("  分 "))]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, {
      class: "bothCenter mt16"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 12,
        class: "leftCenter"
      }, {
        default: _withCtx(() => [_cache[12] || (_cache[12] = _createElementVNode("span", {
          style: {
            "color": "red"
          }
        }, "*", -1)), _createElementVNode("span", null, "多选题（" + _toDisplayString($data.setting.questionDetails[1].count) + "道），每题", 1)]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 12,
        class: "leftCenter"
      }, {
        default: _withCtx(() => [_withDirectives(_createElementVNode("input", {
          class: "settingInput",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.setting.questionDetails[1].score = $event)
        }, null, 512), [[_vModelText, $data.setting.questionDetails[1].score]]), _cache[13] || (_cache[13] = _createTextVNode("  分 "))]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, {
      class: "bothCenter mt16"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 12,
        class: "leftCenter"
      }, {
        default: _withCtx(() => [_cache[14] || (_cache[14] = _createElementVNode("span", {
          style: {
            "color": "red"
          }
        }, "*", -1)), _createElementVNode("span", null, "判断题（" + _toDisplayString($data.setting.questionDetails[2].count) + "道），每题", 1)]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 12,
        class: "leftCenter"
      }, {
        default: _withCtx(() => [_withDirectives(_createElementVNode("input", {
          class: "settingInput",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.setting.questionDetails[2].score = $event)
        }, null, 512), [[_vModelText, $data.setting.questionDetails[2].score]]), _cache[15] || (_cache[15] = _createTextVNode("  分 "))]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, {
      class: "colFlex mt16"
    }, {
      default: _withCtx(() => [_cache[16] || (_cache[16] = _createElementVNode("span", {
        style: {
          "color": "red"
        }
      }, "请输入整数", -1)), _createElementVNode("span", _hoisted_5, "题目总数：" + _toDisplayString($data.setting.questionDetails[0].count + $data.setting.questionDetails[1].count + $data.setting.questionDetails[2].count) + "题 总分：" + _toDisplayString($data.setting.questionDetails[0].count * $data.setting.questionDetails[0].score + $data.setting.questionDetails[1].count * $data.setting.questionDetails[1].score + $data.setting.questionDetails[2].count * $data.setting.questionDetails[2].score) + "分", 1)]),
      _: 1
    }), _createVNode(_component_el_row, {
      class: "bothCenter mt16"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        type: "primary",
        style: {
          "width": "40%"
        },
        onClick: $options.updateRepoSetting
      }, {
        default: _withCtx(() => _cache[17] || (_cache[17] = [_createTextVNode("确定")])),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["visible-control"])], 64);
}